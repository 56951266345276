import Swal from 'sweetalert2';
import { FETCH_OUTLET, SUBMIT_OUTLET, DELETE_OUTLET, REFRESH_OUTLET_DATATABLES } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

import { removeAuthenticatedUser } from './authenticatedUser';

export const fetchOutlet = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/outlet`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_OUTLET, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            if (response[API_RESPONSE_MESSAGE].indexOf('Unauthenticated') >= 0) {
              dispatch(removeAuthenticatedUser());
            } else {
              dispatch({ type: FETCH_OUTLET, result: [] });
              Swal.fire('Fetch Outlet Failed', response[API_RESPONSE_MESSAGE], 'error');
            }
        }
      });
  };
};

export const refreshOutletDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_OUTLET_DATATABLES });
  };
};

/**
 * @param data      name, open_time, close_time, lc_price, pin
 */
export const submitOutlet = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/outlet`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_OUTLET, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshOutletDatatables()))
      .then(() => dispatch(fetchOutlet()));
  };
};

/**
 * @param id
 * @param data      name, open_time, close_time, lc_price, pin
 */
export const updateOutlet = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/outlet/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_OUTLET, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshOutletDatatables()))
      .then(() => dispatch(fetchOutlet()));
  };
};

/**
 * @param id
 */
export const deleteOutlet = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/outlet/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_OUTLET, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshOutletDatatables()))
      .then(() => dispatch(fetchOutlet()));
  };
};
