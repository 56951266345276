import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './store';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './assets/styles/app.css';

import PrivateRoute from './PrivateRoute';

// Layouts
import Auth from './views/Auth';
import Backoffice from './views/Backoffice';

const store = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        {/* Routes with Layouts */}
        <Route path="/auth" component={Auth} />
        <PrivateRoute path="/backoffice" component={Backoffice} />

        {/* Redirect to Home if routes not found */}
        <Redirect from="*" to="/auth" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
