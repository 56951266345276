import Swal from 'sweetalert2';
import { FETCH_ROLE } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchRole = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/role`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_ROLE, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_ROLE, result: [] });
            Swal.fire('Fetch Role Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const emptyFetchedRole = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_ROLE, result: [] });
  };
};
