import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  BasicButton,
  SubmitButton,
} from '../../../../components/Base';
import FilterOutlet from '../../FilterOutlet';

import { fetchRoomType, emptyFetchedRoomType } from '../../../../actions/roomType';
import { submitRoom, updateRoom } from '../../../../actions/room';

function RoomCreate(props, ref) {
  const { register, errors, handleSubmit } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialRoom, setInitialRoom] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { filterOutlet } = useSelector((state) => state.filter);
  const { result: roomTypes } = useSelector((state) => state.roomType);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    dispatch(fetchRoomType());
    return () => dispatch(emptyFetchedRoomType());
  }, []);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialRoom(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialRoom(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    // Change Data Format
    data.outlet_id = filterOutlet.id;

    if (initialRoom) {
      dispatch(updateRoom(initialRoom.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitRoom(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialRoom ? 'Ubah Room' : 'Tambah Room Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Outlet
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <FilterOutlet />
                      </div>
                    </div>
                  </div>
                </div>

                <InlineTextInput
                  type="text"
                  title="Nama Room"
                  inputRef={register({ required: true })}
                  name="name"
                  defaultValue={initialRoom?.name}
                  errorMessage={errors.name && 'Kolom Nama Room harus diisi'}
                />

                <InlineTextInput
                  type="number"
                  title="Harga (Rp)"
                  inputRef={register({ required: true })}
                  name="price"
                  defaultValue={initialRoom ? initialRoom.price : '0'}
                  errorMessage={errors.price && 'Kolom Harga harus diisi'}
                />

                <InlineSelect
                  title="Tipe Room"
                  inputRef={register({ required: true })}
                  name="room_type_id"
                  options={roomTypes.map((roomType) => (
                    <option key={roomType.id} value={roomType.id}>
                      {roomType.name}
                    </option>
                  ))}
                  defaultValue={initialRoom?.room_type_id}
                  errorMessage={errors.room_type_id && 'Kolom Tipe Room harus dipilih'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(RoomCreate);
