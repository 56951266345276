import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Modal, InlineTextInput, BasicButton, SubmitButton } from '../../../../components/Base';
import { updateOutlet } from '../../../../actions/outlet';

function LcCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialOutlet, setInitialOutlet] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialOutlet) {
      setTimeout(() => {
        setValue('lc_price', initialOutlet.lc_price);
      }, 100);
    }
  }, [initialOutlet]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialOutlet(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialOutlet(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    data.name = initialOutlet.name;
    data.open_time = initialOutlet.open_time;
    data.close_time = initialOutlet.close_time;
    if (initialOutlet) {
      dispatch(updateOutlet(initialOutlet.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              Ubah Harga LC
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Nama Outlet
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">{initialOutlet?.name}</div>
                  </div>
                </div>

                <InlineTextInput
                  type="number"
                  title="Harga LC"
                  inputRef={register({ required: true })}
                  name="lc_price"
                  errorMessage={errors.lc_price && 'Kolom Harga LC harus diisi'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(LcCreate);
