import React from 'react';
import $ from 'jquery';

export const APP_ENV = process.env.NODE_ENV;
export const APP_NAME = 'eL Group Internal';
export const APP_KEY = 'EL-INTERNAL';

/* API CREDENTIALS */
const API_URL_ENV = {
  development: 'http://localhost/elgroup-internal-api/public/api',
  // test: 'http://localhost/mypos-sc-api/public/api',
  production: 'https://internal-api.elgroup.id/api',
};

export const API_URL = API_URL_ENV[process.env.NODE_ENV];
export const API_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  dataType: 'json',
  'X-Requested-With': 'XMLHttpRequest',
};
export const API_HEADER_MULTIPART = {
  Accept: 'application/json',
  // 'Content-Type': 'multipart/form-data',
  dataType: 'json',
  'X-Requested-With': 'XMLHttpRequest',
};

export const API_RESPONSE_STATUS = 'status';
export const API_RESPONSE_MESSAGE = 'message';
export const API_RESPONSE = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

/* LOCAL STORAGE KEY */
export const AUTHENTICATED_USER_STORAGE_KEY = `@${APP_KEY}:authenticatedUser`;

/* STATICS VARIABLES */
export const DATATABLES_DEFAULT_CONFIG = {
  processing: true,
  serverSide: true,
  deferRender: true,
  /* dom: 'Bfrtip',
  language: {
    url: '//cdn.datatables.net/plug-ins/1.10.19/i18n/Indonesian.json',
  }, */
  dom: `<''
    <'sm:flex justify-center items-center'
      <'sm:flex-1 text-center sm:text-left'B>
      <'sm:flex-1 text-center sm:text-right'f>
    >
    <'dt-table mt-3'
      <''tr>
    >
    <'sm:flex mt-3 justify-center items-center'
      <'sm:flex-1 text-center sm:text-left'l>
      <'sm:flex-1 text-center sm:text-right'p>
    >
  >`,
  buttons: [
    {
      extend: 'csv',
      action: function (e, dt, button, config) {
        const exportTitle = $(this.nodes()).closest('.base.content').find('h4.title').text();
        config.title = exportTitle;

        if ($.fn.dataTable.ext.buttons.csvHtml5.available(dt, config)) {
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        } else {
          $.fn.dataTable.ext.buttons.csvFlash.action.call(this, e, dt, button, config);
        }
      },
    },
    {
      extend: 'excel',
      action: function (e, dt, button, config) {
        const exportTitle = $(this.nodes()).closest('.base.content').find('h4.title').text();
        config.title = exportTitle;

        if ($.fn.dataTable.ext.buttons.excelHtml5.available(dt, config)) {
          $.fn.dataTable.ext.buttons.excelHtml5.action.call(this, e, dt, button, config);
        } else {
          $.fn.dataTable.ext.buttons.excelFlash.action.call(this, e, dt, button, config);
        }
      },
    },
    {
      extend: 'pdf',
      action: function (e, dt, button, config) {
        const exportTitle = $(this.nodes()).closest('.base.content').find('h4.title').text();
        config.title = exportTitle;

        const tableColumnCount = $(this.nodes()).closest('.base.content').find('th').length;
        if (tableColumnCount > 12) {
          config.orientation = 'landscape';
        }

        if (tableColumnCount > 17) {
          config.pageSize = 'A3';
        }

        if (tableColumnCount > 23) {
          config.pageSize = 'A2';
        }

        if (tableColumnCount > 35) {
          config.pageSize = 'A1';
        }

        if ($.fn.dataTable.ext.buttons.pdfHtml5.available(dt, config)) {
          $.fn.dataTable.ext.buttons.pdfHtml5.action.call(this, e, dt, button, config);
        } else {
          $.fn.dataTable.ext.buttons.pdfFlash.action.call(this, e, dt, button, config);
        }
      },
    },
    {
      extend: 'print',
      action: function (e, dt, button, config) {
        const exportTitle = $(this.nodes()).closest('.base.content').find('h4.title').text();
        config.title = exportTitle;

        $.fn.dataTable.ext.buttons.print.action.call(this, e, dt, button, config);
      },
    },
  ],
};

export const BOOK_STATUS = {
  '-1': (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
      Cancelled
    </span>
  ),
  0: (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
      Pending
    </span>
  ),
  1: (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
      Menunggu Pembayaran
    </span>
  ),
  2: (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
      Selesai
    </span>
  ),
};
export const BOOK_STATUS_STRING = {
  '-1': '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Cancelled</span>',
  0: '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">Pending</span>',
  1: '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">Menunggu Pembayaran</span>',
  2: '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Selesai</span>',
};
export const BOOK_STATUS_COLOR = {
  '-1': 'red',
  0: 'gray',
  1: 'yellow',
  2: 'green',
};

export const THERAPIST_TYPES = [
  { name: 'Therapist', duration: 60 },
  { name: 'LC', duration: 60 },
  { name: 'SPA', duration: 45 },
  { name: 'PR Club', duration: 360 },
];
export const BOOK_TIME_INTERVAL = 10;
