import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import $ from 'jquery';

import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../constants/env';
import Utils from '../../../helpers/utils';

export default function AmountPerPaymentMethod() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterOutlet, filterDate } = useSelector((state) => state.filter);
  const { refresh } = useSelector((state) => state.book);

  useEffect(() => {
    initializeDataTables();
  }, [filterOutlet, filterDate, refresh]);

  const initializeDataTables = () => {
    $('#amountPaymentDataTable').DataTable().destroy();
    $('#amountPaymentDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/report/amount-per-payment-method?source=datatables&o=${filterOutlet.id}&s=${filterDate.startDate}&e=${filterDate.endDate}`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        {
          data: null,
          render: () => moment(filterDate.date).format('YYYY-MM-DD'),
        },
        { data: 'name' },
        {
          data: 'books',
          render: (data) => (data[0] ? Utils.numberFormat(data[0].amount, 2) : 0),
        },
        {
          data: 'books',
          render: (data) => (data[0] ? Utils.numberFormat(data[0].tips, 2) : 0),
        },
        {
          data: 'books',
          render: (data) => (data[0] ? Utils.numberFormat(data[0].additional_charges, 2) : 0),
        },
        {
          data: 'books',
          render: (data) => (data[0] ? Utils.numberFormat(data[0].additional_charge_pools, 2) : 0),
        },
        {
          data: 'book_fnb_payments',
          render: (data) => (data[0] ? Utils.numberFormat(data[0].amount, 2) : 0),
        },
        {
          data: null,
          render: (data, type, row) => {
            const serviceTotal = row.books[0]
              ? row.books[0].amount +
                row.books[0].tips +
                row.books[0].additional_charges +
                row.books[0].additional_charge_pools
              : 0;
            const fnbTotal = row.book_fnb_payments[0] ? row.book_fnb_payments[0].amount : 0;
            return `<span class="font-bold">${Utils.numberFormat(
              serviceTotal + fnbTotal,
              2,
            )}</span>`;
          },
        },
      ],
    });
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Total per Payment Method</h3>
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table
            id="amountPaymentDataTable"
            className="whitespace-nowrap items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Tanggal
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Payment Method
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Service
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Tips
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Add. Charge
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Add. Charge Pool
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  FnB
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Total
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
