import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Modal, BasicButton } from '../../../../components/Base';
import { BOOK_STATUS } from '../../../../constants/env';
import Utils from '../../../../helpers/utils';

// import { updateBook } from 'actions/book';

function BookDetail(props, ref) {
  const [isVisible, setIsVisible] = useState(false);
  const [initialBook, setInitialBook] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: paymentMethods } = useSelector((state) => state.paymentMethod);
  // const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
  }));

  const showForm = (data) => {
    setInitialBook(data);
    setIsVisible(true);
  };
  const closeForm = () => {
    setIsVisible(false);
    setInitialBook(null);
    setIsSubmittingForm(false);
  };

  /* const onPressStartService = () => {
    setIsSubmittingForm(true);

    const updateData = {
      status: 1,
      actual_start_time: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    dispatch(updateBook(initialBook.id, updateData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
  };

  const onPressFinishService = () => {
    setIsSubmittingForm(true);

    const updateData = {
      status: 2,
      actual_end_time: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    dispatch(updateBook(initialBook.id, updateData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
  }; */

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      onClose={closeForm}
      ModalContent={
        initialBook ? (
          <div className="mt-3 sm:mt-0 text-left">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Booking Information</h3>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Outlet</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{initialBook.outlet_name}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Booking Time</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {moment(initialBook.book_time).format('DD MMM YYYY HH:mm')}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Customer</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {initialBook.customer_name}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Therapist</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {initialBook.therapist_id
                      ? `${initialBook.therapist_name} (${initialBook.therapist_grade_name})`
                      : '-'}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Tipe Therapist</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {initialBook.therapist_id ? initialBook.therapist_type : '-'}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Status</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {BOOK_STATUS[initialBook.status]}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">GRO</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {initialBook.gro_name ? initialBook.gro_name : '-'}
                  </dd>
                </div>

                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Aktual Jam Mulai</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {initialBook.actual_start_time
                      ? moment(initialBook.actual_start_time).format('DD MMM YYYY HH:mm')
                      : '-'}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Aktual Jam Selesai</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {initialBook.actual_end_time
                      ? moment(initialBook.actual_end_time).format('DD MMM YYYY HH:mm')
                      : '-'}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Room</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {initialBook.room_name ? initialBook.room_name : ''}
                  </dd>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">&nbsp;</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">&nbsp;</dd>
                </div>

                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Kasir</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {initialBook.cashier_name ? initialBook.cashier_name : ''}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Harga Therapist</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    Rp {Utils.numberFormat(initialBook.therapist_price, 2)}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Harga Room</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    Rp {Utils.numberFormat(initialBook.room_price, 2)}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Discount</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    (Rp {Utils.numberFormat(initialBook.discount, 2)})
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Tips</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    Rp {Utils.numberFormat(initialBook.tip, 2)}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Add. Charge</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    Rp {Utils.numberFormat(initialBook.additional_charge, 2)}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Add. Charge Pool</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    Rp {Utils.numberFormat(initialBook.additional_charge_pool, 2)}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Metode Pembayaran</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {initialBook.payment_method_name ? initialBook.payment_method_name : '-'}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Catatan</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{initialBook.remarks}</dd>
                </div>

                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Pembayaran FnB</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {paymentMethods.map((paymentMethod) => {
                      const p = initialBook.book_fnb_payments.find(
                        (d) => d.payment_method_id === paymentMethod.id,
                      );
                      return (
                        <div className="flex">
                          <span className="w-1/3 font-bold">{paymentMethod.name} </span>
                          <span>{`Rp ${Utils.numberFormat(p.price, 2)}`}</span>
                        </div>
                      );
                    })}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        ) : (
          <div></div>
        )
      }
      ModalButton={
        <>
          {/*
            initialBook && initialBook.status === 0 && (
              <SubmitButton
                type="button"
                text="Start Service"
                color="yellow"
                textClass="text-white text-xs"
                isLoading={isSubmittingForm}
                onClick={onPressStartService}
              />
            )
          }

          {
            initialBook && initialBook.status === 1 && (
              <SubmitButton
                type="button"
                text="Finish Service"
                color="green"
                textClass="text-white text-xs"
                isLoading={isSubmittingForm}
                onClick={onPressFinishService}
              />
            )
          */}

          <BasicButton
            text="Cancel"
            textClass="text-white text-xs mr-2"
            isLoading={isSubmittingForm}
            onClick={closeForm}
          />
        </>
      }
    />
  );
}

export default forwardRef(BookDetail);
