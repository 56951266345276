import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { filterByOutlet } from '../../actions/filter';

export default function FilterOutlet() {
  const dispatch = useDispatch();
  const { result: outlets } = useSelector((state) => state.outlet);
  const { filterOutlet } = useSelector((state) => state.filter);

  useEffect(() => {
    if (outlets.length > 0 && !filterOutlet.id) dispatch(filterByOutlet(outlets[0]));
  }, [outlets]);

  const onChange = (e) => {
    const selectedOutlet = outlets.find((outlet) => outlet.id === Number(e.target.value));
    dispatch(filterByOutlet(selectedOutlet));
  };

  return (
    <select
      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
      name="filter_outlet"
      value={filterOutlet.id}
      onChange={onChange}>
      <option value="">Pilih Outlet</option>
      {outlets.map((outlet) => (
        <option key={outlet.id} value={outlet.id}>
          {outlet.name}
        </option>
      ))}
    </select>
  );
}
