import { FETCH_MEMBER_REPORT, RESET_STORE } from '../actions/_types';

const initialState = {
  result: [],
};

export default function memberReport(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBER_REPORT:
      return {
        ...state,
        result: action.result,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
