import Swal from 'sweetalert2';
import {
  FETCH_PAYMENT_METHOD,
  SUBMIT_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  REFRESH_PAYMENT_METHOD_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchPaymentMethod = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/payment-method`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PAYMENT_METHOD, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PAYMENT_METHOD, result: [] });
            Swal.fire('Fetch Payment Method Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const emptyFetchedPaymentMethod = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PAYMENT_METHOD, result: [] });
  };
};

export const refreshPaymentMethodDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_PAYMENT_METHOD_DATATABLES });
  };
};

/**
 * @param data      name
 */
export const submitPaymentMethod = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/payment-method`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_PAYMENT_METHOD, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshPaymentMethodDatatables()))
      .then(() => dispatch(fetchPaymentMethod()));
  };
};

/**
 * @param id
 * @param data      name
 */
export const updatePaymentMethod = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/payment-method/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_PAYMENT_METHOD, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshPaymentMethodDatatables()))
      .then(() => dispatch(fetchPaymentMethod()));
  };
};

/**
 * @param id
 */
export const deletePaymentMethod = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/payment-method/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_PAYMENT_METHOD, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshPaymentMethodDatatables()))
      .then(() => dispatch(fetchPaymentMethod()));
  };
};
