import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Dialog } from '@headlessui/react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  BasicButton,
  SubmitButton,
  DeleteButton,
} from '../../../../components/Base';
import FilterOutlet from '../../FilterOutlet';
import FilterDate from '../../FilterDate';
import { BOOK_STATUS, BOOK_TIME_INTERVAL } from '../../../../constants/env';

import { submitClubBook, updateClubBook, deleteClubBook } from '../../../../actions/clubBook';

function ClubBookCreate(props, ref) {
  const { register, errors, control, handleSubmit } = useForm();

  const { filterOutlet, filterDate } = useSelector((state) => state.filter);
  const { result: rooms } = useSelector((state) => state.room);
  const { result: marketings } = useSelector((state) => state.marketing);
  const { result: paymentMethods } = useSelector((state) => state.paymentMethod);
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [initialClubBook, setInitialClubBook] = useState(null);

  const [bookingStatus, setBookingStatus] = useState(0);
  const [bookingSchedules, setBookingSchedules] = useState([]);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useEffect(() => {
    mapBookingSchedules();
  }, [filterOutlet, filterDate]);

  useEffect(() => {
    if (initialClubBook) {
      setTimeout(() => {
        setBookingStatus(initialClubBook.status);
      }, 100);
    } else {
      // Set Data to default Empty Form
      setBookingStatus(0);
    }
  }, [initialClubBook]);

  //

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialClubBook(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialClubBook(data);
    showForm();
  };

  //

  const mapBookingSchedules = () => {
    const startTime = moment(filterDate.startDate);
    const endTime = moment(filterDate.endDate);
    const openDuration = moment.duration(endTime.diff(startTime)).as('minutes');

    const schedules = [];
    for (let index = 0; index < openDuration / BOOK_TIME_INTERVAL; index++) {
      const momentDate = moment(filterDate.startDate).add(BOOK_TIME_INTERVAL * index, 'minutes');
      const value = momentDate.format('YYYY-MM-DD HH:mm:ss');
      schedules.push({
        value,
        label: moment(filterDate.startDate)
          .add(BOOK_TIME_INTERVAL * index, 'minutes')
          .format('DD MMM YYYY hh:mm A'),
      });
    }

    setBookingSchedules(schedules);
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    // Change Data Format
    data.outlet_id = filterOutlet.id;
    data.marketing_id = data.marketing.value;
    data.book_time = data.book_time.value;
    data.room_id = data.room.value;

    if (initialClubBook) {
      dispatch(updateClubBook(initialClubBook.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitClubBook(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  const onPressDelete = () => {
    dispatch(deleteClubBook(initialClubBook.id))
      .then(() => closeForm())
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:mx-2 sm:text-left">
            <div className="flex items-center">
              <div className="flex flex-1">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  {initialClubBook ? 'Ubah Booking' : 'Tambah Booking Baru'}
                </Dialog.Title>
                <div className="ml-3">{initialClubBook && BOOK_STATUS[initialClubBook.status]}</div>
              </div>
              <button type="button" className="focus:outline-none" onClick={closeForm}>
                <XIcon className="h-7 w-7 opacity-50" />
              </button>
            </div>

            <div className="mt-8">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Outlet*
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <FilterOutlet />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Tanggal*
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <FilterDate />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Jam Booking*
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <Controller
                          control={control}
                          name="book_time"
                          rules={{ required: true }}
                          defaultValue={
                            initialClubBook
                              ? bookingSchedules.find((s) => s.value === initialClubBook.book_time)
                              : bookingSchedules[0]
                          }
                          render={({ onChange, value }) => (
                            <Select
                              className="w-full"
                              onChange={onChange}
                              value={value}
                              options={bookingSchedules}
                            />
                          )}
                        />
                      </div>

                      {errors.book_time && (
                        <span className="text-xs text-red-500">Kolom Jam Booking harus diisi</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Marketing*
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <Controller
                          control={control}
                          name="marketing"
                          rules={{ required: true }}
                          defaultValue={
                            initialClubBook
                              ? {
                                  value: initialClubBook.marketing_id,
                                  label: `${initialClubBook.marketing?.name} (Ph: ${initialClubBook.marketing?.phone})`,
                                }
                              : null
                          }
                          render={({ onChange, value }) => (
                            <Select
                              className="w-full"
                              onChange={onChange}
                              value={value}
                              options={marketings.map((m) => ({
                                value: m.id,
                                label: `${m.name} (Ph: ${m.phone})`,
                              }))}
                            />
                          )}
                        />
                      </div>

                      {errors.marketing && (
                        <span className="text-xs text-red-500">Kolom Marketing harus dipilih</span>
                      )}
                    </div>
                  </div>
                </div>

                <InlineTextInput
                  type="text"
                  title="Nama Customer*"
                  inputRef={register({ required: true })}
                  name="customer_name"
                  defaultValue={initialClubBook?.customer_name}
                  errorMessage={errors.customer_name && 'Kolom Nama Customer harus diisi'}
                />

                <InlineTextInput
                  type="text"
                  title="No HP Customer"
                  inputRef={register({ required: false })}
                  name="customer_phone"
                  defaultValue={initialClubBook?.customer_phone}
                />

                <InlineTextInput
                  type="number"
                  title="Pax*"
                  inputRef={register({ required: true })}
                  name="pax"
                  defaultValue={initialClubBook?.pax}
                  errorMessage={errors.customer_name && 'Kolom Pax harus diisi'}
                />

                <div className="mt-10">
                  {initialClubBook && (
                    <InlineSelect
                      title="Status*"
                      inputRef={register({ required: true })}
                      name="status"
                      options={[
                        <option key={0} value={0}>
                          Pending
                        </option>,
                        <option key={1} value={1}>
                          Service telah Dimulai
                        </option>,
                        <option key={2} value={2}>
                          Service telah Selesai
                        </option>,
                      ]}
                      onChange={(e) => setBookingStatus(Number(e.target.value))}
                      defaultValue={initialClubBook?.status}
                      errorMessage={errors.status && 'Kolom Status harus dipilih'}
                    />
                  )}

                  <div className="relative w-full mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                      <div>
                        <label className="block uppercase text-gray-700 text-xs font-bold">
                          Room No*
                        </label>
                      </div>

                      <div className="col-span-2 xl:col-span-3">
                        <div className="relative flex flex-wrap items-stretch w-full">
                          <Controller
                            control={control}
                            name="room"
                            rules={{ required: true }}
                            defaultValue={
                              initialClubBook && initialClubBook.room_id
                                ? {
                                    value: initialClubBook.room_id,
                                    label: initialClubBook.room_name,
                                  }
                                : null
                            }
                            render={({ onChange, onBlur, value }) => (
                              <Select
                                className="w-full"
                                onChange={onChange}
                                value={value}
                                options={rooms.map((room) => ({
                                  value: room.id,
                                  label: room.name,
                                }))}
                              />
                            )}
                          />
                        </div>

                        {errors.room && (
                          <span className="text-xs text-red-500">Kolom Room No harus dipilih</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-10">
                  <InlineTextInput
                    type="text"
                    title="Nama Kasir"
                    inputRef={register({ required: bookingStatus === 2 })}
                    name="cashier_name"
                    defaultValue={initialClubBook?.cashier_name}
                    errorMessage={errors.cashier_name && 'Kolom Nama Kasir harus diisi'}
                  />

                  <InlineTextInput
                    type="number"
                    title="Total Spent"
                    inputRef={register({ required: bookingStatus === 2 })}
                    name="total_price"
                    defaultValue={initialClubBook ? initialClubBook.total_price : '0'}
                    errorMessage={errors.total_price && 'Kolom Total Spent harus diisi'}
                  />

                  <InlineSelect
                    title="Metode Pembayaran"
                    inputRef={register({ required: bookingStatus === 2 })}
                    name="payment_method_id"
                    options={paymentMethods.map((paymentMethod) => (
                      <option key={paymentMethod.id} value={paymentMethod.id}>
                        {paymentMethod.name}
                      </option>
                    ))}
                    defaultValue={initialClubBook?.payment_method_id}
                    errorMessage={
                      errors.payment_method_id && 'Kolom Metode Pembayaran harus dipilih'
                    }
                  />

                  <InlineTextInput
                    type="text"
                    title="Catatan"
                    inputRef={register()}
                    name="remarks"
                    defaultValue={initialClubBook?.remarks}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />

          {initialClubBook && (
            <div className="flex flex-1">
              <DeleteButton
                color="red"
                text="Cancel / Delete"
                textClass="text-white text-xs"
                onClick={onPressDelete}
              />
            </div>
          )}
        </>
      }
    />
  );
}

export default forwardRef(ClubBookCreate);
