import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SubmitButton } from '../../../components/Base';
import FilterOutlet from '../FilterOutlet';
import FilterDate from '../FilterDate';
import BookPerTherapist from './BookPerTherapist';
import BookHistory from './BookHistory';
import AmountPerPaymentMethod from './AmountPerPaymentMethod';
import BookCreate from './Create';
import BookCreateFnb from './CreateFnb';
import BookDetail from './Detail';

import { fetchPaymentMethod, emptyFetchedPaymentMethod } from '../../../actions/paymentMethod';
import {
  fetchDailyAttendance,
  emptyFetchedDailyAttendance,
} from '../../../actions/dailyAttendance';
import { fetchRoom, emptyFetchedRoom } from '../../../actions/room';

export default function Book() {
  const bookFormRef = useRef();
  const bookFnbFormRef = useRef();
  const bookDetailRef = useRef();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterOutlet, filterDate } = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const [hasCreatePermission] = useState(authenticatedUser.user.role_id !== 5);

  useEffect(() => {
    dispatch(fetchPaymentMethod());
    // return () => dispatch(emptyFetchedPaymentMethod());
  }, []);

  useEffect(() => {
    dispatch(fetchDailyAttendance(filterOutlet.id, filterDate.date));
    return () => dispatch(emptyFetchedDailyAttendance());
  }, [filterOutlet, filterDate]);

  useEffect(() => {
    dispatch(fetchRoom(filterOutlet.id));
    return () => dispatch(emptyFetchedRoom());
  }, [filterOutlet]);

  const onPressCreate = () => bookFormRef.current.showForm();
  const onPressCreateFnb = () => bookFnbFormRef.current.showForm();

  return (
    <>
      <div className="md:flex">
        <div className="mb-3 w-full md:w-3/12 md:pr-2">
          <FilterOutlet />
        </div>
        <div className="mb-3 w-full md:w-3/12">
          <FilterDate />
        </div>
        <div className="block md:w-6/12 text-right">
          {hasCreatePermission && [
            <SubmitButton
              key="create"
              textClass="text-white text-xs mr-1"
              text="Tambah Booking"
              onClick={onPressCreate}
            />,
            <SubmitButton
              key="create-fnb"
              textClass="text-white text-xs"
              text="Booking FnB / Pool"
              onClick={onPressCreateFnb}
            />,
          ]}
        </div>
      </div>

      <div className="mt-3">
        <BookPerTherapist formRef={bookFormRef} detailRef={bookDetailRef} />
        <BookHistory formRef={bookFormRef} fnbFormRef={bookFnbFormRef} detailRef={bookDetailRef} />
        <AmountPerPaymentMethod />
        {hasCreatePermission && <BookCreate ref={bookFormRef} />}
        {hasCreatePermission && <BookCreateFnb ref={bookFnbFormRef} />}
        <BookDetail ref={bookDetailRef} />
      </div>
    </>
  );
}
