import { FILTER_BY_OUTLET, FILTER_BY_DATE, FILTER_BY_RANGE_DATE, FILTER_BY_MONTH } from './_types';
import moment from 'moment';

export const filterByOutlet = (outlet) => {
  return (dispatch) => {
    dispatch({ type: FILTER_BY_OUTLET, result: outlet });
  };
};

export const filterByDate = (startDate, endDate) => {
  return (dispatch) => {
    const date = moment(startDate).format('YYYY-MM-DD');
    dispatch({ type: FILTER_BY_DATE, result: { date, startDate, endDate } });
  };
};

export const filterByRangeDate = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({ type: FILTER_BY_RANGE_DATE, result: { startDate, endDate } });
  };
};

export const filterByMonth = (date) => {
  return (dispatch) => {
    const filterMonth = {
      date: moment(date).format('YYYY-MM'),
      startDate: moment(date).format('YYYY-MM-01 00:00:00'),
      endDate: moment(date).add(1, 'months').format('YYYY-MM-01 00:00:00'),
    };
    dispatch({ type: FILTER_BY_MONTH, result: filterMonth });
  };
};
