import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, BasicButton, SubmitButton } from '../../../../components/Base';
import FilterOutlet from '../../FilterOutlet';
import FilterDate from '../../FilterDate';

import { fetchTherapist, emptyFetchedTherapist } from '../../../../actions/therapist';
import {
  fetchDailyAttendance,
  emptyFetchedDailyAttendance,
  submitDailyAttendance,
} from '../../../../actions/dailyAttendance';

function DailyAttendanceCreate(props, ref) {
  const { errors, control, handleSubmit } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [selectedTherapists, setSelectedTherapists] = useState([]);

  const { filterOutlet, filterDate } = useSelector((state) => state.filter);
  const { result: therapists } = useSelector((state) => state.therapist);
  const { result: dailyAttendances } = useSelector((state) => state.dailyAttendance);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
  }));

  useEffect(() => {
    dispatch(fetchTherapist());
    return () => dispatch(emptyFetchedTherapist());
  }, []);

  useEffect(() => {
    dispatch(fetchDailyAttendance(filterOutlet.id, filterDate.date));
    return () => dispatch(emptyFetchedDailyAttendance());
  }, [filterOutlet, filterDate]);

  useEffect(() => {
    mapTherapists();
  }, [therapists, dailyAttendances]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setIsSubmittingForm(false);
  };

  const mapTherapists = () => {
    const unavailableTherapistIds = dailyAttendances.map((a) => a.therapist_id);
    const availableTherapists = therapists.filter((t) => unavailableTherapistIds.indexOf(t.id) < 0);
    setSelectedTherapists(availableTherapists);
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    // Change Data Format
    data.outlet_id = filterOutlet.id;
    data.date = filterDate.date;
    data.therapist_id = data.therapist_id.map((therapist) => therapist.value);

    dispatch(submitDailyAttendance(data))
      .then(() => closeForm())
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div>
          <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              Tambah Daily Attendance
            </Dialog.Title>
            <div className="mt-5">
              <form
                id="createForm"
                onSubmit={handleSubmit(onSubmitForm)}
                className="min-h-60screen">
                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Outlet
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <FilterOutlet />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Tanggal
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <FilterDate />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Therapists
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <Controller
                          control={control}
                          name="therapist_id"
                          rules={{ required: true }}
                          defaultValue={[]}
                          render={({ onChange, onBlur, value }) => (
                            <Select
                              className="w-full"
                              onChange={onChange}
                              value={value}
                              closeMenuOnSelect={false}
                              isMulti
                              options={selectedTherapists.map((therapist) => ({
                                value: therapist.id,
                                label: `${therapist.name} (${therapist.therapist_grade.name})`,
                              }))}
                            />
                          )}
                        />
                      </div>

                      {errors.therapist_id && (
                        <span className="text-xs text-red-500">Kolom Therapist harus diisi</span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(DailyAttendanceCreate);
