import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import $ from 'jquery';

import { API_URL, DATATABLES_DEFAULT_CONFIG, BOOK_STATUS_COLOR } from '../../../constants/env';

export default function ClubBookPerRoom({ formRef, detailRef }) {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterOutlet, filterDate } = useSelector((state) => state.filter);
  const { refresh } = useSelector((state) => state.clubBook);

  const [hasCreatePermission] = useState(authenticatedUser.user.role_id !== 5);

  useEffect(() => {
    initializeDataTables();
  }, [filterOutlet, filterDate, refresh]);

  const initializeDataTables = () => {
    $('#roomBookDataTable').DataTable().destroy();
    $('#roomBookDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      serverSide: false,
      ajax: {
        url: `${API_URL}/report/club-book-per-room?source=datatables&o=${filterOutlet.id}&d=${filterDate.date}`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'name' },
        { data: 'room_type.name' },
        { data: 'club_books.length' },
        {
          data: 'club_books',
          searchable: false,
          orderable: false,
          createdCell: (cell, cellData) =>
            ReactDOM.render(
              <div>
                {cellData.map((book) => (
                  <button
                    key={book.id}
                    type="button"
                    className={`px-2 py-1 mr-2 my-1 rounded bg-${BOOK_STATUS_COLOR[book.status]}-100
                      text-left text-base text-${BOOK_STATUS_COLOR[book.status]}-800
                      ${
                        book.payment_method_id
                          ? `border border-${BOOK_STATUS_COLOR[book.status]}-400`
                          : 'border-2 border-red-600'
                      }
                      leading-none`}
                    onClick={() =>
                      book.status !== 2 && hasCreatePermission
                        ? onPressEdit(book)
                        : onPressView(book)
                    }>
                    {moment(book.book_time).format('hh:mm A')}
                    <br />
                    <span className="text-xs">{`${book.customer_name} (${book.customer_phone})`}</span>
                  </button>
                ))}
              </div>,
              cell,
            ),
        },
      ],
      footerCallback: function () {
        const api = this.api();
        const footerData = api.ajax.json()?.footerData;

        // Show the calculated amount to Footer
        $(api.column(0).footer()).html('<strong>TOTAL BOOKING</strong>');
        $(api.column(2).footer()).html(
          `<strong>${footerData ? footerData.booksTotal : 0}</strong>`,
        );
      },
    });
  };
  const onPressView = (data) => detailRef.current.showForm(data);
  const onPressEdit = (data) => formRef.current.showFormWithInitialData(data);

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Booking per Seat / Table</h3>
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          <table
            id="roomBookDataTable"
            className="items-center min-w-full w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Seat
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Type
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Jumlah Booking
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-1/2">
                  Booking
                </th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th colSpan="2" className="text-left"></th>
                <th className="text-left"></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
}
