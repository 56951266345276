import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';

import FilterOutlet from '../FilterOutlet';
import MarketingCreate from './Create';
import { DeleteButton, SubmitButton } from '../../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../constants/env';

import { deleteMarketing } from '../../../actions/marketing';

export default function Marketing() {
  const marketingFormRef = useRef();
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterOutlet } = useSelector((state) => state.filter);
  const { refresh } = useSelector((state) => state.marketing);

  const dispatch = useDispatch();

  useEffect(() => {
    initializeDataTables();
  }, [filterOutlet, refresh]);

  const initializeDataTables = () => {
    $('#dataTable').DataTable().destroy();
    $('#dataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/marketing?source=datatables&o=${filterOutlet.id}`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'name' },
        { data: 'phone' },
        {
          data: 'id',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="blue"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="Edit"
                  onClick={() => onPressEdit(rowData)}
                />
                <DeleteButton
                  color="red"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2"
                  onClick={() => onPressDelete(rowData)}
                />
              </div>,
              cell,
            ),
        },
      ],
    });
  };

  const onPressCreate = () => marketingFormRef.current.showForm();
  const onPressEdit = (data) => marketingFormRef.current.showFormWithInitialData(data);
  const onPressDelete = (data) => dispatch(deleteMarketing(data.id));

  return (
    <>
      <div className="md:flex">
        <div className="mb-3 w-full md:w-3/12 md:pr-2">
          <FilterOutlet />
        </div>
        <div className="mb-3 w-full md:w-3/12"></div>
        <div className="block md:w-6/12 text-right">
          <SubmitButton
            textClass="text-white text-xs"
            text="Tambah Marketing"
            onClick={onPressCreate}
          />
          <MarketingCreate ref={marketingFormRef} />
        </div>
      </div>

      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Marketing</h3>
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table id="dataTable" className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Nama
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Phone
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-40"></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
