import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Utils from '../../../helpers/utils';

import { fetchMemberReport } from '../../../actions/memberReport';

export default function DailySummary() {
  const tbodyColumnClass = 'px-6 py-3 border border-solid border-gray-200 border-l-0 border-r-0';

  const { filterOutlet, filterDate } = useSelector((state) => state.memberFilter);
  const { result } = useSelector((state) => state.memberReport);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMemberReport(filterOutlet?.id, filterDate.startDate, filterDate.endDate));
  }, [dispatch, filterOutlet, filterDate]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Transaction Summary</h3>
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table className="items-center min-w-full w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase">
                  Outlet
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase">
                  Date
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase">
                  Payment
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase">
                  Debit
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0s text-xs uppercase">
                  Credit
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(result).map((dataPerOutlets) =>
                Object.entries(dataPerOutlets[1]).map((dataPerDates, dIndex) =>
                  dataPerDates[1].map((transaction, index) => [
                    <tr
                      key={`${dataPerOutlets[0]}/${dataPerDates[0]}/${transaction.payment_method_name}`}>
                      <td className={tbodyColumnClass}>
                        {dIndex === 0 && index === 0 ? dataPerOutlets[0] : ' '}
                      </td>
                      <td className={tbodyColumnClass}>{index === 0 ? dataPerDates[0] : ' '}</td>
                      {index === dataPerDates[1].length - 1
                        ? [
                            <td className={`${tbodyColumnClass} font-bold`}>
                              {transaction.payment_method_name}
                            </td>,
                            <td className={`${tbodyColumnClass} text-right font-bold`}>
                              {Utils.numberFormat(transaction.debit)}
                            </td>,
                            <td className={`${tbodyColumnClass} text-right font-bold`}>
                              {Utils.numberFormat(transaction.credit)}
                            </td>,
                          ]
                        : [
                            <td className={tbodyColumnClass}>{transaction.payment_method_name}</td>,
                            <td className={`${tbodyColumnClass} text-right`}>
                              {Utils.numberFormat(transaction.debit)}
                            </td>,
                            <td className={`${tbodyColumnClass} text-right`}>
                              {Utils.numberFormat(transaction.credit)}
                            </td>,
                          ]}
                    </tr>,
                    index === dataPerDates[1].length - 1 && (
                      <tr
                        key={`${dataPerOutlets[0]}/${dataPerDates[0]}/${transaction.payment_method_name}-spacing`}>
                        <td className={tbodyColumnClass}></td>
                        <td className={tbodyColumnClass}></td>
                        <td className={tbodyColumnClass}></td>
                        <td className={tbodyColumnClass}></td>
                        <td className={tbodyColumnClass}></td>
                      </tr>
                    ),
                  ]),
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
