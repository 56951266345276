import Swal from 'sweetalert2';
import { FETCH_MEMBER, SUBMIT_MEMBER, DELETE_MEMBER, REFRESH_MEMBER_DATATABLES } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchMember = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/member`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_MEMBER, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_MEMBER, result: [] });
            Swal.fire('Fetch Member Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const emptyFetchedMember = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_MEMBER, result: [] });
  };
};

export const refreshMemberDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_MEMBER_DATATABLES });
  };
};

/**
 * @param data      card_no
 */
/* export const submitMember = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/member`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_MEMBER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMemberDatatables()))
      .then(() => dispatch(fetchMember()));
  };
}; */

/**
 * @param id
 * @param data      member_type, name, phone
 */
export const updateMember = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/member/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_MEMBER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMemberDatatables()));
  };
};

/**
 * @param id
 */
export const deleteMember = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/member/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_MEMBER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMemberDatatables()));
  };
};

//

/**
 * @param data      transaction_type, transactions[{ member_id, outlet_id, amount }]
 */
export const requestMemberTopupOtp = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/member-transaction/request-otp`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Request Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_MEMBER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Request Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMemberDatatables()));
  };
};

/**
 * @param data      transaction_type, transactions[{ member_id, outlet_id, amount }], otp
 */
export const submitMemberTopup = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/member-transaction/bulk-transaction`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_MEMBER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMemberDatatables()));
  };
};
