import { combineReducers } from 'redux';

import authenticatedUser from './authenticatedUser';
import auth from './auth';
import password from './password';

import filter from './filter';
import book from './book';
import clubBook from './clubBook';
import dailyAttendance from './dailyAttendance';

import memberFilter from './memberFilter';
import memberReport from './memberReport';
import member from './member';

import role from './role';
import roomType from './roomType';
import therapist from './therapist';
import therapistGrade from './therapistGrade';
import marketing from './marketing';
import user from './user';
import paymentMethod from './paymentMethod';
import room from './room';
import outlet from './outlet';

export default combineReducers({
  authenticatedUser,
  auth,
  password,

  filter,
  book,
  clubBook,
  dailyAttendance,

  memberFilter,
  memberReport,
  member,

  role,
  roomType,
  therapist,
  therapistGrade,
  marketing,
  user,
  paymentMethod,
  room,
  outlet,
});
