import {
  FETCH_CLUB_BOOK,
  SUBMIT_CLUB_BOOK,
  DELETE_CLUB_BOOK,
  REFRESH_CLUB_BOOK_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],

  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function clubBook(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLUB_BOOK:
      return {
        ...state,
        result: action.result,
      };

    case SUBMIT_CLUB_BOOK:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_CLUB_BOOK:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_CLUB_BOOK_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
