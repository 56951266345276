import Swal from 'sweetalert2';
import {
  FETCH_CLUB_BOOK,
  SUBMIT_CLUB_BOOK,
  DELETE_CLUB_BOOK,
  REFRESH_CLUB_BOOK_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

import { removeAuthenticatedUser } from './authenticatedUser';

export const fetchClubBook = (outletId, startDate, endDate) => {
  const filterStartDate = startDate ? `&s=${startDate}` : '';
  const filterEndDate = endDate ? `&e=${endDate}` : '';

  return (dispatch) => {
    return fetch(`${API_URL}/club-book?o=${outletId}${filterStartDate}${filterEndDate}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_CLUB_BOOK, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            if (response[API_RESPONSE_MESSAGE].indexOf('Unauthenticated') >= 0) {
              dispatch(removeAuthenticatedUser());
            } else {
              dispatch({ type: FETCH_CLUB_BOOK, result: [] });
              Swal.fire('Fetch Booking Failed', response[API_RESPONSE_MESSAGE], 'error');
            }
        }
      });
  };
};

export const refreshClubBookDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_CLUB_BOOK_DATATABLES });
  };
};

/**
 * @param data      book_time, outlet_id, room_id, total_price, payment_method_id,
 *                  marketing_id, customer_name, customer_phone, cashier_name, pax, remarks
 */
export const submitClubBook = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/club-book`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_CLUB_BOOK, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshClubBookDatatables()));
  };
};

/**
 * @param id
 * @param data      book_time, outlet_id, room_id, status,
 *                  total_price, payment_method_id,
 *                  marketing_id, customer_name, customer_phone, cashier_name, pax, remarks
 */
export const updateClubBook = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/club-book/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_CLUB_BOOK, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshClubBookDatatables()));
  };
};

/**
 * @param id
 */
export const deleteClubBook = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/club-book/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_CLUB_BOOK, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshClubBookDatatables()));
  };
};
