import React from 'react';

export default function TextInput({
  title,
  inputRef,
  errorMessage,
  buttonColor = 'blue',
  button,
  buttonOnClick,
  ...props
}) {
  return (
    <>
      <div className="relative w-full mb-5">
        {title && title.trim() !== '' && (
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password">
            {title}
          </label>
        )}

        <div className="relative flex w-full flex-wrap items-stretch">
          <input
            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            ref={inputRef}
            type="text"
            placeholder={title}
            {...props}
          />

          {button && (
            <div className="z-10 h-full absolute right-0 flex justify-center">
              <button
                className={`bg-transparent text-${buttonColor}-500 font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none text-green-500`}
                type="button"
                onClick={buttonOnClick}>
                {button}
              </button>
            </div>
          )}
        </div>

        {errorMessage && <span className="text-xs text-red-500">{errorMessage}</span>}
      </div>
    </>
  );
}
