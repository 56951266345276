/* AUTHENTICATED DATA */
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const EMPTY_AUTHENTICATED_USER = 'EMPTY_AUTHENTICATED_USER';

export const RESET_STORE = 'RESET_STORE';

/* AUTHENTICATION */
export const SUBMIT_SIGN_IN = 'SUBMIT_SIGN_IN';
export const SUBMIT_SIGN_OUT = 'SUBMIT_SIGN_OUT';

export const SUBMIT_SIGN_UP = 'SUBMIT_SIGN_UP';
export const REQUEST_PHONE_OTP = 'REQUEST_PHONE_OTP';
export const SUBMIT_PHONE_OTP = 'SUBMIT_PHONE_OTP';

/* PROFILE & PASSWORD */
export const SUBMIT_CHANGE_PROFILE = 'SUBMIT_CHANGE_PROFILE';
export const SUBMIT_CHANGE_PROFILE_PICTURE = 'SUBMIT_CHANGE_PROFILE_PICTURE';
export const SUBMIT_CHANGE_PASSWORD = 'SUBMIT_CHANGE_PASSWORD';

//

export const FILTER_BY_OUTLET = 'FILTER_BY_OUTLET';
export const FILTER_BY_DATE = 'FILTER_BY_DATE';
export const FILTER_BY_RANGE_DATE = 'FILTER_BY_RANGE_DATE';
export const FILTER_BY_MONTH = 'FILTER_BY_MONTH';

/* BOOK */
export const FETCH_BOOK = 'FETCH_BOOK';
export const SUBMIT_BOOK = 'SUBMIT_BOOK';
export const DELETE_BOOK = 'DELETE_BOOK';
export const REFRESH_BOOK_DATATABLES = 'REFRESH_BOOK_DATATABLES';

/* CLUB BOOK */
export const FETCH_CLUB_BOOK = 'FETCH_CLUB_BOOK';
export const SUBMIT_CLUB_BOOK = 'SUBMIT_CLUB_BOOK';
export const DELETE_CLUB_BOOK = 'DELETE_CLUB_BOOK';
export const REFRESH_CLUB_BOOK_DATATABLES = 'REFRESH_CLUB_BOOK_DATATABLES';

/* DAILY ATTENDANCE */
export const FETCH_DAILY_ATTENDANCE = 'FETCH_DAILY_ATTENDANCE';
export const SUBMIT_DAILY_ATTENDANCE = 'SUBMIT_DAILY_ATTENDANCE';
export const DELETE_DAILY_ATTENDANCE = 'DELETE_DAILY_ATTENDANCE';
export const REFRESH_DAILY_ATTENDANCE_DATATABLES = 'REFRESH_DAILY_ATTENDANCE_DATATABLES';

//

/* MEMBER TRANSACTION */
export const FILTER_MEMBER_TRANSACTION_BY_OUTLET = 'FILTER_MEMBER_TRANSACTION_BY_OUTLET';
export const FILTER_MEMBER_TRANSACTION_BY_DATE = 'FILTER_MEMBER_TRANSACTION_BY_DATE';

export const FETCH_MEMBER_REPORT = 'FETCH_MEMBER_REPORT';

/* MEMBER */
export const FETCH_MEMBER = 'FETCH_MEMBER';
export const SUBMIT_MEMBER = 'SUBMIT_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const REFRESH_MEMBER_DATATABLES = 'REFRESH_MEMBER_DATATABLES';

export const REQUEST_MEMBER_TOPUP_OTP = 'REQUEST_MEMBER_TOPUP_OTP';
export const SUBMIT_MEMBER_TOPUP = 'SUBMIT_MEMBER_TOPUP';

//

/* THERAPIST */
export const FETCH_THERAPIST = 'FETCH_THERAPIST';
export const SUBMIT_THERAPIST = 'SUBMIT_THERAPIST';
export const DELETE_THERAPIST = 'DELETE_THERAPIST';
export const REFRESH_THERAPIST_DATATABLES = 'REFRESH_THERAPIST_DATATABLES';

/* THERAPIST GRADE */
export const FETCH_THERAPIST_GRADE = 'FETCH_THERAPIST_GRADE';
export const SUBMIT_THERAPIST_GRADE = 'SUBMIT_THERAPIST_GRADE';
export const DELETE_THERAPIST_GRADE = 'DELETE_THERAPIST_GRADE';
export const REFRESH_THERAPIST_GRADE_DATATABLES = 'REFRESH_THERAPIST_GRADE_DATATABLES';

/* MARKETING */
export const FETCH_MARKETING = 'FETCH_MARKETING';
export const SUBMIT_MARKETING = 'SUBMIT_MARKETING';
export const DELETE_MARKETING = 'DELETE_MARKETING';
export const REFRESH_MARKETING_DATATABLES = 'REFRESH_MARKETING_DATATABLES';

/* USER */
export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_USER = 'FETCH_USER';
export const SUBMIT_USER = 'SUBMIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const REFRESH_USER_DATATABLES = 'REFRESH_USER_DATATABLES';

/* PAYMENT METHOD */
export const FETCH_PAYMENT_METHOD = 'FETCH_PAYMENT_METHOD';
export const SUBMIT_PAYMENT_METHOD = 'SUBMIT_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const REFRESH_PAYMENT_METHOD_DATATABLES = 'REFRESH_PAYMENT_METHOD_DATATABLES';

/* ROOM */
export const FETCH_ROOM_TYPE = 'FETCH_ROOM_TYPE';
export const FETCH_ROOM = 'FETCH_ROOM';
export const SUBMIT_ROOM = 'SUBMIT_ROOM';
export const DELETE_ROOM = 'DELETE_ROOM';
export const REFRESH_ROOM_DATATABLES = 'REFRESH_ROOM_DATATABLES';

/* OUTLET */
export const FETCH_OUTLET = 'FETCH_OUTLET';
export const SUBMIT_OUTLET = 'SUBMIT_OUTLET';
export const DELETE_OUTLET = 'DELETE_OUTLET';
export const REFRESH_OUTLET_DATATABLES = 'REFRESH_OUTLET_DATATABLES';
