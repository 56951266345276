import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import $ from 'jquery';

import FilterRangeDate from '../FilterRangeDate';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../constants/env';
import moment from 'moment';

export default function Performance() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterRangeDate } = useSelector((state) => state.filter);

  useEffect(() => {
    initializeDataTables();
    initializeGroDataTables();
    initializeMarketingDataTables();
  }, [filterRangeDate]);

  const initializeDataTables = () => {
    $('#therapistDataTable').DataTable().destroy();
    $('#therapistDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/report/therapist-performance?source=datatables&s=${filterRangeDate.startDate}&e=${filterRangeDate.endDate}`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        {
          data: 'date',
          render: () =>
            `${moment(filterRangeDate.startDate).format('DD MMM YYYY')} - ${moment(
              filterRangeDate.endDate,
            ).format('DD MMM YYYY')}`,
        },
        { data: 'name' },
        { data: 'therapist_grade.name' },
        {
          data: 'daily_attendances',
          render: (data) => (data[0] ? data[0].attendance_total : 0),
        },
        {
          data: 'books',
          render: (data) => (data[0] ? data[0].book_total : 0),
        },
      ],
    });
  };

  const initializeGroDataTables = () => {
    $('#groDataTable').DataTable().destroy();
    $('#groDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/report/gro-performance?source=datatables&s=${filterRangeDate.startDate}&e=${filterRangeDate.endDate}`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        {
          data: 'date',
          render: () =>
            `${moment(filterRangeDate.startDate).format('DD MMM YYYY')} - ${moment(
              filterRangeDate.endDate,
            ).format('DD MMM YYYY')}`,
        },
        { data: 'gro_name' },
        { data: 'no_of_books' },
      ],
    });
  };

  const initializeMarketingDataTables = () => {
    $('#marketingDataTable').DataTable().destroy();
    $('#marketingDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      serverSide: false,
      ajax: {
        url: `${API_URL}/report/marketing-performance?source=datatables&s=${filterRangeDate.startDate}&e=${filterRangeDate.endDate}`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        {
          data: 'date',
          render: () =>
            `${moment(filterRangeDate.startDate).format('DD MMM YYYY')} - ${moment(
              filterRangeDate.endDate,
            ).format('DD MMM YYYY')}`,
        },
        { data: 'name' },
        { data: 'phone' },
        { data: 'total_table' },
        { data: 'total_sofa' },
      ],
    });
  };

  return (
    <>
      <div className="md:flex">
        <div className="mb-3 w-full md:w-3/12 md:pr-2">
          <FilterRangeDate />
        </div>
        <div className="mb-3 w-full md:w-3/12"></div>
        <div className="block md:w-6/12 text-right"></div>
      </div>

      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Therapist Performance</h3>
          </div>
        </div>
        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          <table
            id="therapistDataTable"
            className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left w-48">
                  Date
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Therapist
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Grade
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left w-40">
                  Num of Attendance
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left w-40">
                  Num of Booking
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>

      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">GRO Performance</h3>
          </div>
        </div>
        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          <table id="groDataTable" className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left w-48">
                  Date
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  GRO
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Num of Booking
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>

      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-4 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Marketing Performance</h3>
          </div>
        </div>
        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          <table
            id="marketingDataTable"
            className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left w-48">
                  Date
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Marketing
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Phone
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left w-32">
                  Booking (Table)
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left w-32">
                  Booking (Sofa)
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
