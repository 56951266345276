import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import $ from 'jquery';

import MemberForm from './Create';
import TopupForm from './TopupForm';
import { DeleteButton, SubmitButton } from '../../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../constants/env';
import Utils from '../../../helpers/utils';

import { deleteMember } from '../../../actions/member';

export default function Member() {
  const memberFormRef = useRef();
  const topupFormRef = useRef();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { refresh } = useSelector((state) => state.member);
  const dispatch = useDispatch();

  useEffect(() => {
    initializeDataTables();
  }, [refresh]);

  const initializeDataTables = () => {
    if ($.fn.DataTable.isDataTable('#memberDataTable')) {
      $('#memberDataTable').DataTable().destroy();
    }
    $('#memberDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/member?source=datatables`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'card_no', title: 'Card No', footer: 'TOTAL SALDO' },
        { data: 'name', title: 'Nama' },
        { data: 'phone', title: 'Phone' },
        { data: 'member_type', title: 'Tipe Member' },
        { data: 'outlet_name', title: 'Outlet Name' },
        {
          data: 'join_date',
          title: 'Tanggal Join',
          render: (data) => moment(data).format('DD MMM YYYY'),
        },
        {
          data: 'balance',
          title: 'Saldo',
          searchable: false,
          sortable: false,
          render: (data) => Utils.numberFormat(data),
        },
        {
          data: 'id',
          width: 140,
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="blue"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="Edit"
                  onClick={() => onPressEdit(rowData)}
                />
                {Number(rowData.balance) === 0 && (
                  <DeleteButton
                    color="red"
                    textClass="text-white text-xs"
                    paddingClass="px-3 py-2"
                    onClick={() => onPressDelete(rowData)}
                  />
                )}
              </div>,
              cell,
            ),
        },
      ],
      columnDefs: [{ targets: [6], className: 'dt-right' }],
      footerCallback: function () {
        const api = this.api();
        const footerData = api.ajax.json()?.footerData;

        $(api.column(0).footer()).html('<strong>TOTAL SALDO</strong>');
        $(api.column(6).footer()).html(
          footerData ? Utils.numberFormat(footerData.balanceTotal) : 0,
        );
      },
    });
  };

  // const onPressCreate = () => therapistGradeFormRef.current.showForm();
  const onPressEdit = (data) => memberFormRef.current.showFormWithInitialData(data);
  const onPressDelete = (data) => dispatch(deleteMember(data.id));

  const onPressTopup = () => topupFormRef.current.showForm();

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Member</h3>
          </div>
          <div className="px-4">
            <SubmitButton
              textClass="text-white text-xs"
              text="Top-up / Refund"
              onClick={onPressTopup}
            />
            <MemberForm ref={memberFormRef} />
            <TopupForm ref={topupFormRef} />
          </div>
        </div>

        {/* Table Content */}
        <div className="p-6 w-full block">
          <table id="memberDataTable" className="w-full border-collapse">
            <tfoot>
              <tr>
                <th colSpan={6}></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
}
