import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// DataTables
import 'jszip';
import 'pdfmake';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-fixedcolumns-dt';
// import 'datatables.net-responsive-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
// import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';

// Components
import BackofficeNavbar from './BackofficeNavbar';
import BackofficeSidebar from './BackofficeSidebar';
import BackofficeFooter from './BackofficeFooter';

// Views
import Book from './Book';
import ClubBook from './ClubBook';
import Performance from './Performance';
import DailyAttendance from './DailyAttendance';

import MemberReport from './MemberReport';
import Member from './Member';

import Therapist from './Therapist';
import TherapistGrade from './TherapistGrade';
import Marketing from './Marketing';
import Lc from './Lc';
import User from './User';
import PaymentMethod from './PaymentMethod';
import Room from './Room';
import Outlet from './Outlet';

import Password from './Password';

import { fetchOutlet } from '../../actions/outlet';

export default function Backoffice() {
  const dispatch = useDispatch();

  // Fetch Initial Data
  useEffect(() => {
    dispatch(fetchOutlet());
  }, []);

  return (
    <>
      <BackofficeSidebar />
      <div className="relative md:ml-64 bg-gray-100">
        <BackofficeNavbar />
        <div className="w-full min-h-screen px-4 md:px-10 pt-10 md:pt-24 mx-auto">
          <div className="min-h-100">
            <Switch>
              <Route path="/backoffice/dashboard" exact component={Book} />
              <Redirect from="/backoffice/book" to="/backoffice/dashboard" />
              <Route path="/backoffice/club-dashboard" exact component={ClubBook} />
              <Redirect from="/backoffice/club-book" to="/backoffice/club-dashboard" />
              <Route path="/backoffice/performance" exact component={Performance} />
              <Route path="/backoffice/daily-attendance" exact component={DailyAttendance} />

              <Route path="/backoffice/member-report" exact component={MemberReport} />
              <Route path="/backoffice/member" exact component={Member} />

              <Route path="/backoffice/therapist" exact component={Therapist} />
              <Route path="/backoffice/grade" exact component={TherapistGrade} />
              <Route path="/backoffice/marketing" exact component={Marketing} />
              <Route path="/backoffice/lc" exact component={Lc} />
              <Route path="/backoffice/user" exact component={User} />
              <Route path="/backoffice/payment-method" exact component={PaymentMethod} />
              <Route path="/backoffice/room" exact component={Room} />
              <Route path="/backoffice/outlet" exact component={Outlet} />

              <Route path="/backoffice/password" exact component={Password} />

              <Redirect from="/backoffice" to="/backoffice/dashboard" />
            </Switch>
          </div>

          <div className="mt-10">
            <BackofficeFooter />
          </div>
        </div>
      </div>
    </>
  );
}
