import { FILTER_MEMBER_TRANSACTION_BY_OUTLET, FILTER_MEMBER_TRANSACTION_BY_DATE } from './_types';

export const filterByOutlet = (outlet) => {
  return (dispatch) => {
    dispatch({ type: FILTER_MEMBER_TRANSACTION_BY_OUTLET, result: outlet });
  };
};

export const filterByDate = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({ type: FILTER_MEMBER_TRANSACTION_BY_DATE, result: { startDate, endDate } });
  };
};
