import Swal from 'sweetalert2';
import {
  FETCH_DAILY_ATTENDANCE,
  SUBMIT_DAILY_ATTENDANCE,
  DELETE_DAILY_ATTENDANCE,
  REFRESH_DAILY_ATTENDANCE_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

import { removeAuthenticatedUser } from './authenticatedUser';

export const fetchDailyAttendance = ($outletId, $date) => {
  return (dispatch) => {
    return fetch(`${API_URL}/daily-attendance?o=${$outletId}&d=${$date}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_DAILY_ATTENDANCE, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            if (response[API_RESPONSE_MESSAGE].indexOf('Unauthenticated') >= 0) {
              dispatch(removeAuthenticatedUser());
            } else {
              dispatch({ type: FETCH_DAILY_ATTENDANCE, result: [] });
              Swal.fire('Fetch Attendance Failed', response[API_RESPONSE_MESSAGE], 'error');
            }
        }
      });
  };
};

export const emptyFetchedDailyAttendance = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_DAILY_ATTENDANCE, result: [] });
  };
};

export const refreshDailyAttendanceDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_DAILY_ATTENDANCE_DATATABLES });
  };
};

/**
 * @param data      date, outlet_id, therapist_id[]
 */
export const submitDailyAttendance = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/daily-attendance`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_DAILY_ATTENDANCE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshDailyAttendanceDatatables()));
  };
};

/**
 * @param id
 */
export const deleteDailyAttendance = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/daily-attendance/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_DAILY_ATTENDANCE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshDailyAttendanceDatatables()));
  };
};
