import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Views
import Login from './Login';
// import Register from './Register';

const authBackground = require('../../assets/img/auth-background.jpg');

export default function Auth() {
  const { result } = useSelector((state) => state.authenticatedUser);
  return result ? (
    <Redirect from="*" to="/backoffice" />
  ) : (
    <>
      <main>
        <section className="relative w-full h-full py-20 min-h-screen">
          <div
            className="fixed top-0 w-full h-full bg-no-repeat bg-cover"
            style={{ backgroundImage: `url(${authBackground})` }}></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />

            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </section>
      </main>
    </>
  );
}
