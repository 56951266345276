import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function Modal({
  isVisible = false,
  size = 'medium',
  onClose = () => {},
  initialFocus = null,
  ModalContent,
  ModalButton,
}) {
  const width = {
    small: 'max-w-lg',
    medium: 'max-w-xl',
    large: 'max-w-2xl',
    xlarge: 'max-w-4xl',
  };
  const modalWidth = width[size] || 'max-w-xl';

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={initialFocus}
        open={isVisible}
        onClose={onClose}>
        <div className="flex justify-center py-4 px-4 text-center sm:block sm:p-0">
          {/* MODAL BACKGROUND */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* MODAL CONTENT */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              className={`inline-block align-center bg-white rounded-lg overflow-hidden shadow-xl
              transform transition-all sm:my-8 md:my-12 sm:align-middle sm:${modalWidth} w-full`}>
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">{ModalContent}</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {ModalButton}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default React.memo(Modal);
