import {
  FILTER_MEMBER_TRANSACTION_BY_OUTLET,
  FILTER_MEMBER_TRANSACTION_BY_DATE,
} from '../actions/_types';
import moment from 'moment';

const initialState = {
  filterOutlet: null,
  filterDate: {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
};

export default function memberFilter(state = initialState, action) {
  switch (action.type) {
    case FILTER_MEMBER_TRANSACTION_BY_OUTLET:
      return {
        ...state,
        filterOutlet: action.result,
      };

    case FILTER_MEMBER_TRANSACTION_BY_DATE:
      return {
        ...state,
        filterDate: { ...action.result },
      };

    default:
      return state;
  }
}
