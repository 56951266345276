import Swal from 'sweetalert2';
import {
  FETCH_THERAPIST,
  SUBMIT_THERAPIST,
  DELETE_THERAPIST,
  REFRESH_THERAPIST_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

import { removeAuthenticatedUser } from './authenticatedUser';

export const fetchTherapist = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/therapist`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_THERAPIST, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            if (response[API_RESPONSE_MESSAGE].indexOf('Unauthenticated') >= 0) {
              dispatch(removeAuthenticatedUser());
            } else {
              dispatch({ type: FETCH_THERAPIST, result: [] });
              Swal.fire('Fetch Therapist Failed', response[API_RESPONSE_MESSAGE], 'error');
            }
        }
      });
  };
};

export const emptyFetchedTherapist = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_THERAPIST, result: [] });
  };
};

export const refreshTherapistDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_THERAPIST_DATATABLES });
  };
};

/**
 * @param data      therapist_grade_id, name
 */
export const submitTherapist = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/therapist`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_THERAPIST, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshTherapistDatatables()))
      .then(() => dispatch(fetchTherapist()));
  };
};

/**
 * @param id
 * @param data      therapist_grade_id, name
 */
export const updateTherapist = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/therapist/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_THERAPIST, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshTherapistDatatables()))
      .then(() => dispatch(fetchTherapist()));
  };
};

/**
 * @param id
 */
export const deleteTherapist = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/therapist/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_THERAPIST, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshTherapistDatatables()))
      .then(() => dispatch(fetchTherapist()));
  };
};
