import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import ReactDatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { Modal, InlineTextInput, BasicButton, SubmitButton } from '../../../../components/Base';
import { submitOutlet, updateOutlet } from '../../../../actions/outlet';

function OutletCreate(props, ref) {
  const { register, errors, control, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialOutlet, setInitialOutlet] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialOutlet) {
      setTimeout(() => {
        setValue('name', initialOutlet.name);
        setValue(
          'open_time',
          new Date(
            `${moment().format('YYYY-MM-DD')} ${initialOutlet.open_time}`.replace(/-/g, '/'),
          ),
        );
        setValue(
          'close_time',
          new Date(
            `${moment().format('YYYY-MM-DD')} ${initialOutlet.close_time}`.replace(/-/g, '/'),
          ),
        );
        setValue('pin', initialOutlet.pin);
      }, 100);
    }
  }, [initialOutlet]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialOutlet(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialOutlet(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    // Change Date Format
    data.open_time = moment(data.open_time).format('HH:mm:ss');
    data.close_time = moment(data.close_time).format('HH:mm:ss');

    if (initialOutlet) {
      dispatch(updateOutlet(initialOutlet.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitOutlet(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialOutlet ? 'Ubah Outlet' : 'Tambah Outlet Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form
                id="createForm"
                onSubmit={handleSubmit(onSubmitForm)}
                style={{ minHeight: '60vh' }}>
                <InlineTextInput
                  type="text"
                  title="Nama Outlet"
                  inputRef={register({ required: true })}
                  name="name"
                  errorMessage={errors.name && 'Kolom Nama Outlet harus diisi'}
                />

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Jam Buka
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <div className="px-3 py-3 text-sm text-gray-700 bg-white rounded shadow w-full ease-linear transition-all duration-150">
                          <Controller
                            control={control}
                            name="open_time"
                            rules={{ required: true }}
                            defaultValue={new Date()}
                            render={({ onChange, onBlur, value }) => (
                              <ReactDatePicker
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                showTimeSelect
                                showTimeSelectOnly
                                dateFormat="hh:mm:ss a"
                                timeIntervals={60}
                              />
                            )}
                          />
                        </div>
                      </div>

                      {errors.open_time && (
                        <span className="text-xs text-red-500">
                          Kolom Jam Buka Outlet harus diisi
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Jam Tutup
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <div className="px-3 py-3 text-sm text-gray-700 bg-white rounded shadow w-full ease-linear transition-all duration-150">
                          <Controller
                            control={control}
                            name="close_time"
                            rules={{ required: true }}
                            defaultValue={new Date()}
                            render={({ onChange, onBlur, value }) => (
                              <ReactDatePicker
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                showTimeSelect
                                showTimeSelectOnly
                                dateFormat="hh:mm:ss a"
                                timeIntervals={60}
                              />
                            )}
                          />
                        </div>
                      </div>

                      {errors.close_time && (
                        <span className="text-xs text-red-500">
                          Kolom Jam Tutup Outlet harus diisi
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <InlineTextInput
                  type="text"
                  title="PIN Transaksi"
                  placeholder="6-digits PIN Transaksi"
                  inputRef={register({
                    required: true,
                    minLength: 6,
                    maxLength: 6,
                    pattern: /^[0-9]+$/i,
                  })}
                  name="pin"
                  errorMessage={
                    errors.pin && 'Kolom PIN Transaksi harus diisi dengan 6-digit numeric'
                  }
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(OutletCreate);
