const utils = {
  formatPhoneUsingCodeArea: (p) => {
    let phone = p
      .replace(/^\s+|\s+$/g, '') // Trim
      .replace(/[^0-9]/g, ''); // Remove chars other than digits

    // If first char is 0, then replace to +62
    if (phone.indexOf('0') === 0) phone = phone.replace('0', '+62');

    // If first char is 62, then replace to +62
    if (phone.indexOf('62') === 0) phone = phone.replace('62', '+62');

    return phone;
  },

  stringToSlug: (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  },

  round: (value, decimals) => {
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  },

  numberFormat: (number, decimals) => {
    const dec = typeof decimals === 'undefined' ? 0 : decimals;
    const num = Number(`${Math.round(`${number}e${dec}`)}e-${dec}`);
    const s = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return s;
  },
};

export default utils;
