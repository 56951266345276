import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { TextInput, SubmitButton } from '../../../components/Base';
import { submitSignIn } from '../../../actions/auth';

const appLogo = require('../../../assets/img/elgroup.png');

export default function Login() {
  const history = useHistory();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { register, errors, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);
    dispatch(submitSignIn(data)).then(
      () => history.replace('/backoffice/dashboard'),
      (error) => {
        setIsSubmittingForm(false);
      },
    );
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10">
                <div className="flex justify-center mb-6">
                  <img src={appLogo} alt="ELGROUP INTERNAL SYSTEM" className="h-16" />
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <TextInput
                    title="Email"
                    inputRef={register({
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                    })}
                    type="email"
                    name="email"
                    errorMessage={errors.email && 'Kolom Email harus diisi dengan email yang valid'}
                  />

                  <TextInput
                    title="Password"
                    inputRef={register({ required: true })}
                    type="password"
                    name="password"
                    errorMessage={errors.password && 'Kolom Password harus diisi'}
                  />

                  <div className="text-center mt-10">
                    <SubmitButton
                      additionalClass="w-full"
                      type="submit"
                      text="Sign In"
                      isLoading={isSubmittingForm}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
