import Swal from 'sweetalert2';
import {
  FETCH_MARKETING,
  SUBMIT_MARKETING,
  DELETE_MARKETING,
  REFRESH_MARKETING_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchMarketing = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/marketing`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_MARKETING, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_MARKETING, result: [] });
            Swal.fire('Fetch Marketing Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const emptyFetchedMarketing = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_MARKETING, result: [] });
  };
};

export const refreshMarketingDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_MARKETING_DATATABLES });
  };
};

/**
 * @param data      name, phone
 */
export const submitMarketing = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/marketing`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_MARKETING, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMarketingDatatables()))
      .then(() => dispatch(fetchMarketing()));
  };
};

/**
 * @param id
 * @param data      name, phone
 */
export const updateMarketing = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/marketing/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_MARKETING, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMarketingDatatables()))
      .then(() => dispatch(fetchMarketing()));
  };
};

/**
 * @param id
 */
export const deleteMarketing = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/marketing/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_MARKETING, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMarketingDatatables()))
      .then(() => dispatch(fetchMarketing()));
  };
};
