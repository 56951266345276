import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  BasicButton,
  SubmitButton,
} from '../../../../components/Base';

import {
  fetchTherapistGrade,
  emptyFetchedTherapistGrade,
} from '../../../../actions/therapistGrade';
import { submitTherapist, updateTherapist } from '../../../../actions/therapist';

function TherapistCreate(props, ref) {
  const { register, errors, handleSubmit } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialTherapist, setInitialTherapist] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: therapistGrades } = useSelector((state) => state.therapistGrade);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    dispatch(fetchTherapistGrade());
    return () => dispatch(emptyFetchedTherapistGrade());
  }, []);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialTherapist(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialTherapist(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    if (initialTherapist) {
      dispatch(updateTherapist(initialTherapist.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitTherapist(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialTherapist ? 'Ubah Ladies' : 'Tambah Ladies Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <InlineTextInput
                  type="text"
                  title="Nama Ladies"
                  inputRef={register({ required: true })}
                  name="name"
                  defaultValue={initialTherapist?.name}
                  errorMessage={errors.name && 'Kolom Nama Ladies harus diisi'}
                />

                <InlineSelect
                  title="Grade"
                  inputRef={register({ required: true })}
                  name="therapist_grade_id"
                  options={therapistGrades.map((therapistGrade) => (
                    <option key={therapistGrade.id} value={therapistGrade.id}>
                      {therapistGrade.name}
                    </option>
                  ))}
                  defaultValue={initialTherapist?.therapist_grade_id}
                  errorMessage={errors.therapist_grade_id && 'Kolom Grade harus dipilih'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(TherapistCreate);
