import Swal from 'sweetalert2';
import { FETCH_MEMBER_REPORT } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

import { removeAuthenticatedUser } from './authenticatedUser';

export const fetchMemberReport = (outletId, startDate, endDate) => {
  const filterStartDate = startDate ? `&s=${startDate}` : '';
  const filterEndDate = endDate ? `&e=${endDate}` : '';

  return (dispatch) => {
    return fetch(
      `${API_URL}/member-report/daily-transaction-summary?o=${outletId}${filterStartDate}${filterEndDate}`,
      {
        method: 'GET',
        headers: authApiHeader(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_MEMBER_REPORT, result: response.data });
            return;

          case API_RESPONSE.FAILED:
          default:
            if (response[API_RESPONSE_MESSAGE].indexOf('Unauthenticated') >= 0) {
              dispatch(removeAuthenticatedUser());
            } else {
              dispatch({ type: FETCH_MEMBER_REPORT, result: [] });
              Swal.fire('Fetch Report Failed', response[API_RESPONSE_MESSAGE], 'error');
            }
        }
      });
  };
};
