import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';

import FilterOutlet from '../FilterOutlet';
import FilterDate from '../FilterDate';
import DailyAttendanceCreate from './Create';
import { DeleteButton, SubmitButton } from '../../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../constants/env';
import { deleteDailyAttendance } from '../../../actions/dailyAttendance';

export default function DailyAttendance() {
  const dailyAttendanceFormRef = useRef();
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterOutlet, filterDate } = useSelector((state) => state.filter);
  const { refresh } = useSelector((state) => state.dailyAttendance);

  const dispatch = useDispatch();

  useEffect(() => {
    initializeDataTables();
  }, [filterOutlet, filterDate, refresh]);

  const initializeDataTables = () => {
    $('#dataTable').DataTable().destroy();
    $('#dataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/daily-attendance?source=datatables&o=${filterOutlet.id}&d=${filterDate.date}`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'date' },
        { data: 'therapist.name' },
        { data: 'therapist.therapist_grade.name' },
        {
          data: 'id',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <DeleteButton
                  color="red"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2"
                  onClick={() => onPressDelete(rowData)}
                />
              </div>,
              cell,
            ),
        },
      ],
    });
  };

  const onPressCreate = () => dailyAttendanceFormRef.current.showForm();
  const onPressDelete = (data) => dispatch(deleteDailyAttendance(data.id));

  return (
    <>
      <div className="md:flex">
        <div className="mb-3 w-full md:w-3/12 md:pr-2">
          <FilterOutlet />
        </div>
        <div className="mb-3 w-full md:w-3/12">
          <FilterDate />
        </div>
        <div className="block md:w-6/12 text-right">
          <SubmitButton
            textClass="text-white text-xs"
            text="Tambah Attendance"
            onClick={onPressCreate}
          />
          <DailyAttendanceCreate ref={dailyAttendanceFormRef} />
        </div>
      </div>

      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Daily Attendance</h3>
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table id="dataTable" className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Date
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Therapist
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Grade
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-40"></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
