import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import $ from 'jquery';

import { DeleteButton, SubmitButton } from '../../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG, BOOK_STATUS_STRING } from '../../../constants/env';
import Utils from '../../../helpers/utils';
import { deleteBook } from '../../../actions/book';

export default function BookHistory({ formRef, fnbFormRef, detailRef }) {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { result: paymentMethods } = useSelector((state) => state.paymentMethod);
  const { filterOutlet, filterDate } = useSelector((state) => state.filter);
  const { refresh } = useSelector((state) => state.book);
  const dispatch = useDispatch();

  const [hasCreatePermission] = useState(authenticatedUser.user.role_id !== 5);
  const [hasEditPermission] = useState([1, 3].indexOf(authenticatedUser.user.role_id) >= 0);

  useEffect(() => {
    if (paymentMethods.length > 0) initializeDataTables();
  }, [paymentMethods, filterOutlet, filterDate, refresh]);

  const initializeDataTables = () => {
    const payments = paymentMethods.map((paymentMethod) => ({
      data: 'total_price',
      render: (data, type, row) =>
        row.payment_method_id === paymentMethod.id ? Utils.numberFormat(data, 2) : 0,
    }));
    const fnbPayments = paymentMethods.map((paymentMethod) => ({
      data: 'book_fnb_payments',
      render: (data) => {
        const p = data.find((d) => d.payment_method_id === paymentMethod.id);
        return p ? `${Utils.numberFormat(p.price, 2)}` : 0;
      },
    }));

    $('#bookingHistoryDataTable').DataTable().destroy();
    $('#bookingHistoryDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      scrollX: true,
      ajax: {
        url: `${API_URL}/book?source=datatables&o=${filterOutlet.id}&s=${filterDate.startDate}&e=${filterDate.endDate}&trashed=1`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        {
          data: 'book_time',
          render: (data) => moment(data).format('YYYY-MM-DD'),
        },
        { data: 'therapist_name' },
        { data: 'therapist_grade_name' },
        { data: 'therapist_type' },
        {
          data: 'status',
          render: (data) => BOOK_STATUS_STRING[data],
        },

        {
          data: 'book_time',
          render: (data) => (data ? moment(data).format('hh:mm A') : '-'),
        },
        {
          data: 'book_time',
          render: (data, type, row) =>
            data ? moment(data).add(row.duration, 'minutes').format('hh:mm A') : '',
        },
        {
          data: 'actual_start_time',
          render: (data) => (data ? moment(data).format('hh:mm A') : '-'),
        },
        {
          data: 'actual_end_time',
          render: (data) => (data ? moment(data).format('hh:mm A') : '-'),
        },
        { data: 'room_name', render: (data) => (data ? data : '-') },

        {
          data: 'therapist_price',
          render: (data) => Utils.numberFormat(data, 2),
        },
        {
          data: 'room_price',
          render: (data) => Utils.numberFormat(data, 2),
        },
        {
          data: 'discount',
          render: (data) => Utils.numberFormat(data, 2),
        },
        {
          data: 'tip',
          render: (data) => Utils.numberFormat(data, 2),
        },
        {
          data: 'additional_charge',
          render: (data) => Utils.numberFormat(data, 2),
        },
        {
          data: 'additional_charge_pool',
          render: (data) => Utils.numberFormat(data, 2),
        },

        ...payments,

        ...fnbPayments,

        { data: 'customer_name', render: (data) => (data ? data : '-') },
        { data: 'gro_name', render: (data) => (data ? data : '-') },
        { data: 'remarks' },
        { data: 'cashier_name' },
        {
          data: 'deleted_by_user',
          orderable: false,
          render: (data) => (data ? data.name : '-'),
        },
        {
          data: 'id',
          searchable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div className="inline">
                <SubmitButton
                  color="green"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="View"
                  onClick={() => onPressView(rowData)}
                />

                {(([0, 1].indexOf(rowData.status) >= 0 && hasCreatePermission) ||
                  (rowData.status === 2 && hasEditPermission)) && (
                  <>
                    <SubmitButton
                      color="blue"
                      textClass="text-white text-xs"
                      paddingClass="px-3 py-2 mr-1"
                      text="Edit"
                      onClick={() => onPressEdit(rowData)}
                    />
                    <DeleteButton
                      color="red"
                      textClass="text-white text-xs"
                      paddingClass="px-3 py-2"
                      onClick={() => onPressDelete(rowData)}
                    />
                  </>
                )}
              </div>,
              cell,
            ),
        },
      ],
    });
  };

  const onPressView = (data) => detailRef.current.showForm(data);
  const onPressEdit = (data) => {
    if (data.therapist_id) formRef.current.showFormWithInitialData(data);
    else fnbFormRef.current.showFormWithInitialData(data);
  };
  const onPressDelete = (data) => dispatch(deleteBook(data.id));

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Daftar Booking</h3>
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table
            id="bookingHistoryDataTable"
            className="whitespace-nowrap items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Tanggal
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Therapist
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Grade
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Type
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Status
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Start
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Finish
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Actual Start
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Actual Finish
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Room
                </th>

                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Harga Therapist
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Harga Room
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Discount
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Tips
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Add. Charge
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Add. Charge Pool
                </th>

                {/* PAYMENT */}
                {paymentMethods.map((paymentMethod) => (
                  <th
                    key={paymentMethod.id}
                    className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                    {paymentMethod.name}
                  </th>
                ))}

                {/* PAYMENT FNB */}
                {paymentMethods.map((paymentMethod) => (
                  <th
                    key={paymentMethod.id}
                    className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                    {`FNB ${paymentMethod.name}`}
                  </th>
                ))}

                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Customer
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  GRO
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Keterangan
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Cashier
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Delete User
                </th>

                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-40">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
