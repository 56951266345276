import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import reducer from '../reducers';

const loggerMiddleware = createLogger();

export default function configureStore(initialState) {
  const enhancer =
    process.env.NODE_ENV === 'production'
      ? compose(applyMiddleware(thunkMiddleware))
      : compose(applyMiddleware(thunkMiddleware, loggerMiddleware));
  return createStore(reducer, initialState, enhancer);
}
