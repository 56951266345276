import React, { forwardRef, useImperativeHandle, useState } from 'react';
import moment from 'moment';

import { Modal, BasicButton } from '../../../../components/Base';
import { BOOK_STATUS } from '../../../../constants/env';
import Utils from '../../../../helpers/utils';

function BookDetail(props, ref) {
  const [isVisible, setIsVisible] = useState(false);
  const [clubBook, setClubBook] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useImperativeHandle(ref, () => ({
    showForm,
  }));

  const showForm = (data) => {
    setClubBook(data);
    setIsVisible(true);
  };
  const closeForm = () => {
    setIsVisible(false);
    setClubBook(null);
    setIsSubmittingForm(false);
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      onClose={closeForm}
      ModalContent={
        clubBook ? (
          <div className="mt-3 sm:mt-0 text-left">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Booking Information</h3>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Outlet</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clubBook.outlet_name}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Booking Time</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {moment(clubBook.book_time).format('DD MMM YYYY HH:mm')}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Room</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clubBook.room_name}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Room Type</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clubBook.room_type_name}</dd>
                </div>

                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Customer</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clubBook.customer_name}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Customer Phone</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clubBook.customer_phone}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Pax</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clubBook.pax}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Status</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {BOOK_STATUS[clubBook.status]}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Marketing / PR</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {clubBook.marketing_name ? clubBook.marketing_name : '-'}
                  </dd>
                </div>

                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Aktual Jam Mulai</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {clubBook.actual_start_time
                      ? moment(clubBook.actual_start_time).format('DD MMM YYYY HH:mm')
                      : '-'}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Aktual Jam Selesai</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {clubBook.actual_end_time
                      ? moment(clubBook.actual_end_time).format('DD MMM YYYY HH:mm')
                      : '-'}
                  </dd>
                </div>

                <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">&nbsp;</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">&nbsp;</dd>
                </div>

                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Kasir</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clubBook.cashier_name}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Toptal Spent</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    Rp {Utils.numberFormat(clubBook.total_price, 2)}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Metode Pembayaran</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {clubBook.payment_method_name ? clubBook.payment_method_name : '-'}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Catatan</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clubBook.remarks}</dd>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">&nbsp;</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">&nbsp;</dd>
                </div>

                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Inputted By</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clubBook.user_name}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Deleted By</dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    {clubBook.deleted_by_user?.name}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        ) : (
          <div></div>
        )
      }
      ModalButton={
        <>
          <BasicButton
            text="Cancel"
            textClass="text-white text-xs mr-2"
            isLoading={isSubmittingForm}
            onClick={closeForm}
          />
        </>
      }
    />
  );
}

export default forwardRef(BookDetail);
