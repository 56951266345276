import {
  FETCH_DAILY_ATTENDANCE,
  SUBMIT_DAILY_ATTENDANCE,
  DELETE_DAILY_ATTENDANCE,
  REFRESH_DAILY_ATTENDANCE_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],

  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function dailyAttendance(state = initialState, action) {
  switch (action.type) {
    case FETCH_DAILY_ATTENDANCE:
      return {
        ...state,
        result: action.result,
      };

    case SUBMIT_DAILY_ATTENDANCE:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_DAILY_ATTENDANCE:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_DAILY_ATTENDANCE_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
