import React from 'react';

import FilterOutlet from './FilterOutlet';
import FilterDate from './FilterDate';
import DailySummary from './DailySummary';
import MemberTransactionLog from './MemberTransactionLog';

export default function MemberReport() {
  return (
    <>
      <div className="md:flex">
        <div className="mb-3 w-full md:w-3/12 md:pr-2">
          <FilterOutlet />
        </div>
        <div className="mb-3 w-full md:w-3/12">
          <FilterDate />
        </div>
      </div>

      <div className="mt-3">
        <DailySummary />
        <MemberTransactionLog />
      </div>
    </>
  );
}
