import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  BasicButton,
  SubmitButton,
} from '../../../../components/Base';
import { updateMember } from '../../../../actions/member';

function MemberCreate(props, ref) {
  const { register, errors, handleSubmit } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialMember, setInitialMember] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  //

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialMember(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialMember(data);
    showForm();
  };

  //

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    if (initialMember) {
      dispatch(updateMember(initialMember.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  //

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0 w-full text-center sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialMember ? 'Ubah Member' : 'Tambah Member'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <InlineTextInput editable={false} title="Card No" value={initialMember?.card_no} />

                <InlineSelect
                  title="Tipe Member"
                  inputRef={register({ required: true })}
                  name="member_type"
                  options={['REGULAR', 'PRIORITAS'].map((t) => (
                    <option key={t} value={t}>
                      {t}
                    </option>
                  ))}
                  defaultValue={initialMember?.member_type}
                  errorMessage={errors.member_type && 'Kolom Tipe Member harus dipilih'}
                />

                <InlineTextInput
                  title="Nama"
                  inputRef={register({ required: true })}
                  name="name"
                  defaultValue={initialMember?.name}
                  errorMessage={errors.name && 'Kolom Nama harus diisi'}
                />

                <InlineTextInput
                  title="Phone"
                  inputRef={register({ required: true })}
                  name="phone"
                  defaultValue={initialMember?.phone}
                  errorMessage={errors.price && 'Kolom Phone harus diisi'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(MemberCreate);
