import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { filterByDate } from '../../../actions/memberFilter';

export default function FilterDate() {
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const { filterDate } = useSelector((state) => state.memberFilter);

  const showModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  const onChange = ({ dates }) => {
    const startTime = `${moment(dates.startDate).format('YYYY-MM-DD')}`;
    const endTime = `${moment(dates.endDate).format('YYYY-MM-DD')}`;
    dispatch(filterByDate(startTime, endTime));

    if (dates.startDate !== dates.endDate) closeModal();
  };

  return (
    <div className="px-3 py-3 text-sm text-gray-700 bg-white rounded shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
      <div className="cursor-pointer" onClick={showModal}>
        {`${filterDate.startDate} - ${filterDate.endDate}`}
      </div>

      <Transition.Root show={isVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={isVisible}
          onClose={closeModal}>
          <div className="flex justify-center py-4 px-4 text-center sm:block sm:p-0">
            {/* MODAL BACKGROUND */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* MODAL CONTENT */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className={`inline-block align-center bg-white rounded-lg overflow-hidden shadow-xl
                transform transition-all sm:my-8 md:my-12 sm:align-middle`}>
                <DateRangePicker
                  ranges={[
                    {
                      startDate: new Date(filterDate.startDate?.replace(/-/g, '/')),
                      endDate: new Date(filterDate.endDate?.replace(/-/g, '/')),
                      key: 'dates',
                    },
                  ]}
                  onChange={onChange}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
