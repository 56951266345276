import React, { useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { filterByDate } from '../../actions/filter';

export default function FilterDate() {
  const dispatch = useDispatch();
  const { filterOutlet, filterDate } = useSelector((state) => state.filter);

  useEffect(() => {
    if (filterOutlet.open_time && filterOutlet.close_time)
      setFilterDate(new Date(filterDate.date?.replace(/-/g, '/')));
  }, [filterOutlet]);

  const onChange = (date) => {
    setFilterDate(date);
  };

  const setFilterDate = (date) => {
    const startTime = `${moment(date).format('YYYY-MM-DD')} ${filterOutlet.open_time}`;
    let endTime = `${moment(date).format('YYYY-MM-DD')} ${filterOutlet.close_time}`;
    if (endTime <= startTime)
      endTime = moment(endTime).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');

    dispatch(filterByDate(startTime, endTime));
  };

  return (
    <div className="px-3 py-3 text-sm text-gray-700 bg-white rounded shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
      <ReactDatePicker
        onChange={onChange}
        selected={new Date(filterDate.startDate?.replace(/-/g, '/'))}
        dateFormat="yyyy-MM-dd"
      />
    </div>
  );
}
