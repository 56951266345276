import React from 'react';
import ReactSelect from 'react-select';

export default function Select({ styles, ...props }) {
  return (
    <>
      <div className="relative flex flex-wrap items-stretch w-full">
        <ReactSelect
          styles={{
            control: (styles) => ({
              ...styles,
              minHeight: 45,
              border: 0,
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            }),
            ...styles,
          }}
          {...props}
        />
      </div>
    </>
  );
}
