import Swal from 'sweetalert2';
import { SUBMIT_CHANGE_PASSWORD } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

import { removeAuthenticatedUser } from './authenticatedUser';

/**
 * @param data      old_password, new_password, c_new_password
 */
export const changePassword = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/change-password`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_CHANGE_PASSWORD, result: response });
            dispatch(removeAuthenticatedUser());
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      });
  };
};
