import Swal from 'sweetalert2';
import {
  // FETCH_USER,
  SUBMIT_USER,
  DELETE_USER,
  REFRESH_USER_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

/* export const fetchUser = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/user`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then(response => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_USER, result: response.data });
            break;
        
          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_USER, result: [] });
            Swal.fire('Fetch User Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
}; */

export const refreshUserDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_USER_DATATABLES });
  };
};

/**
 * @param data      outlet_id, role_id, name, email, password
 */
export const submitUser = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/user`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_USER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshUserDatatables()));
  };
};

/**
 * @param id
 * @param data      outlet_id, role_id, name, password
 */
export const updateUser = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/user/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_USER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshUserDatatables()));
  };
};

/**
 * @param id
 */
export const deleteUser = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/user/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_USER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshUserDatatables()));
  };
};
