import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal,
  InlineSelect,
  InlineTextInput,
  BasicButton,
  SubmitButton,
} from '../../../../components/Base';

import { fetchRole, emptyFetchedRole } from '../../../../actions/role';
import { submitUser, updateUser } from '../../../../actions/user';

function UserCreate(props, ref) {
  const { register, errors, control, handleSubmit } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialUser, setInitialUser] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: outlets } = useSelector((state) => state.outlet);
  const { result: roles } = useSelector((state) => state.role);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    dispatch(fetchRole());
    return () => dispatch(emptyFetchedRole());
  }, []);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialUser(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialUser(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    data.outlet_ids = data.outlet_ids.map((o) => o.value);

    if (initialUser) {
      dispatch(updateUser(initialUser.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitUser(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialUser ? 'Ubah User' : 'Tambah User Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Outlet
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <Controller
                          control={control}
                          name="outlet_ids"
                          rules={{ required: true }}
                          defaultValue={
                            initialUser && initialUser.outlet_ids
                              ? outlets
                                  .filter(
                                    (outlet) =>
                                      initialUser.outlet_ids.split(',').indexOf(`${outlet.id}`) >=
                                      0,
                                  )
                                  .map((outlet) => ({ value: outlet.id, label: outlet.name }))
                              : []
                          }
                          render={({ onChange, onBlur, value }) => (
                            <Select
                              className="w-full"
                              onChange={onChange}
                              value={value}
                              closeMenuOnSelect={false}
                              isMulti
                              options={outlets.map((outlet) => ({
                                value: outlet.id,
                                label: outlet.name,
                              }))}
                            />
                          )}
                        />
                      </div>

                      {errors.outlet_ids && (
                        <span className="text-xs text-red-500">Kolom Outlet harus diisi</span>
                      )}
                    </div>
                  </div>
                </div>

                {/* <InlineSelect
                  title="Outlet"
                  inputRef={register({ required: true })}
                  name="outlet_id"
                  options={
                    outlets.map(outlet => (
                      <option key={outlet.id} value={outlet.id}>{outlet.name}</option>
                    ))
                  }
                  errorMessage={errors.outlet_id && 'Kolom Outlet harus dipilih'}
                /> */}

                <InlineTextInput
                  type="text"
                  title="Nama"
                  inputRef={register({ required: true })}
                  name="name"
                  defaultValue={initialUser?.name}
                  errorMessage={errors.name && 'Kolom Nama harus diisi'}
                />

                <InlineSelect
                  title="Role"
                  inputRef={register({ required: true })}
                  name="role_id"
                  options={roles.map((role) => (
                    <option value={role.id}>{role.name}</option>
                  ))}
                  defaultValue={initialUser?.role_id}
                  errorMessage={errors.role_id && 'Kolom Role harus dipilih'}
                />

                {!initialUser && (
                  <InlineTextInput
                    type="email"
                    title="Email"
                    inputRef={register({ required: true })}
                    name="email"
                    errorMessage={errors.email && 'Kolom Email harus diisi'}
                  />
                )}

                {initialUser && (
                  <div className="mt-8 mb-2">
                    Silahkan masukan Password baru untuk mengubah Password
                  </div>
                )}
                <InlineTextInput
                  type="password"
                  title="Password"
                  inputRef={register({ required: initialUser ? false : true })}
                  name="password"
                  errorMessage={errors.password && 'Kolom Password harus diisi'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(UserCreate);
