import Swal from 'sweetalert2';
import { SUBMIT_SIGN_IN, SUBMIT_SIGN_UP, REQUEST_PHONE_OTP, SUBMIT_PHONE_OTP } from './_types';
import {
  API_URL,
  API_HEADER,
  API_RESPONSE_STATUS,
  API_RESPONSE_MESSAGE,
  API_RESPONSE,
} from '../constants/env';
import { setAuthenticatedUser, removeAuthenticatedUser } from './authenticatedUser';

/**
 * @param data      email, password
 */
export const submitSignIn = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/login`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: API_HEADER,
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch(setAuthenticatedUser(response.data));
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Login Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
        dispatch({ type: SUBMIT_SIGN_IN, result: response });
      });
  };
};

export const submitSignOut = () => {
  return (dispatch) => {
    dispatch(removeAuthenticatedUser());
    Swal.fire('LogOut Success', 'LogOut dari eL Group Internal berhasil', 'success');
  };
};

/**
 * @param data      email, password
 */
export const requestPhoneOtp = (phone) => {
  return (dispatch) => {
    return fetch(`${API_URL}/otp/send`, {
      method: 'POST',
      body: JSON.stringify({ phone }),
      headers: API_HEADER,
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Request OTP Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
        dispatch({ type: REQUEST_PHONE_OTP, result: response });
      });
  };
};

/**
 * @param data      phone, token
 */
export const submitPhoneOtp = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/otp/verify`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: API_HEADER,
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('OTP Verification Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
        dispatch({ type: SUBMIT_PHONE_OTP, result: response });
      });
  };
};

/**
 * @param data      email, password, c_password, phone, name
 */
export const submitSignUp = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/register`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: API_HEADER,
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire(
              'Registration Success',
              'Silahkan aktifkan akun Kamu dengan cara klik link verifikasi yang telah dikirimkan melalui email',
              'success',
            );
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Registration Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
        dispatch({ type: SUBMIT_SIGN_UP, result: response });
      });
  };
};
