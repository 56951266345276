import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Modal, InlineTextInput, BasicButton, SubmitButton } from '../../../../components/Base';
import { submitTherapistGrade, updateTherapistGrade } from '../../../../actions/therapistGrade';

function TherapistGradeCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialTherapistGrade, setInitialTherapistGrade] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialTherapistGrade) {
      setTimeout(() => {
        setValue('name', initialTherapistGrade.name);
        setValue('price', initialTherapistGrade.price);
      }, 100);
    }
  }, [initialTherapistGrade]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialTherapistGrade(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialTherapistGrade(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    if (initialTherapistGrade) {
      dispatch(updateTherapistGrade(initialTherapistGrade.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitTherapistGrade(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialTherapistGrade ? 'Ubah Ladies Grade' : 'Tambah Ladies Grade Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <InlineTextInput
                  type="text"
                  title="Nama Grade"
                  inputRef={register({ required: true })}
                  name="name"
                  errorMessage={errors.name && 'Kolom Nama Grade harus diisi'}
                />

                <InlineTextInput
                  type="number"
                  title="Harga (Rp)"
                  inputRef={register({ required: true })}
                  name="price"
                  errorMessage={errors.price && 'Kolom Harga harus diisi'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(TherapistGradeCreate);
