import Swal from 'sweetalert2';
import { FETCH_BOOK, SUBMIT_BOOK, DELETE_BOOK, REFRESH_BOOK_DATATABLES } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

import { removeAuthenticatedUser } from './authenticatedUser';
import { refreshDailyAttendanceDatatables } from './dailyAttendance';

export const fetchBook = (outletId, startDate, endDate, therapistId) => {
  const filterStartDate = startDate ? `&s=${startDate}` : '';
  const filterEndDate = endDate ? `&e=${endDate}` : '';
  const filterTherapist = therapistId ? `&t=${therapistId}` : '';

  return (dispatch) => {
    return fetch(
      `${API_URL}/book?o=${outletId}${filterStartDate}${filterEndDate}${filterTherapist}`,
      {
        method: 'GET',
        headers: authApiHeader(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_BOOK, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            if (response[API_RESPONSE_MESSAGE].indexOf('Unauthenticated') >= 0) {
              dispatch(removeAuthenticatedUser());
            } else {
              dispatch({ type: FETCH_BOOK, result: [] });
              Swal.fire('Fetch Booking Failed', response[API_RESPONSE_MESSAGE], 'error');
            }
        }
      });
  };
};

export const refreshBookDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_BOOK_DATATABLES });
  };
};

/**
 * @param data      book_time, daily_attendance_id, outlet_id, room_id, payment_method_id,
 *                  discount, gro_name, customer_name, remarks,
 *                  fnb_payments[{ payment_method_id, price }]
 */
export const submitBook = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/book`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_BOOK, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshDailyAttendanceDatatables()))
      .then(() => dispatch(refreshBookDatatables()));
  };
};

/**
 * @param id
 * @param data      book_time, daily_attendance_id, outlet_id, therapist_id, room_id, payment_method_id,
 *                  discount, tip, additional_charge, gro_name, customer_name, remarks, status,
 *                  actual_start_time, actual_end_time,
 *                  fnb_payments[{ payment_method_id, price }]
 */
export const updateBook = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/book/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_BOOK, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshDailyAttendanceDatatables()))
      .then(() => dispatch(refreshBookDatatables()));
  };
};

/**
 * @param id
 */
export const deleteBook = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/book/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_BOOK, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshDailyAttendanceDatatables()))
      .then(() => dispatch(refreshBookDatatables()));
  };
};
