import {
  FETCH_OUTLET,
  SUBMIT_OUTLET,
  DELETE_OUTLET,
  REFRESH_OUTLET_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function outlet(state = initialState, action) {
  switch (action.type) {
    case FETCH_OUTLET:
      return {
        ...state,
        result: action.result,
      };

    case SUBMIT_OUTLET:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_OUTLET:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_OUTLET_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
