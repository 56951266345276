import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SubmitButton } from '../../../components/Base';
import FilterOutlet from '../FilterOutlet';
import FilterDate from '../FilterDate';
import ClubBookPerRoom from './ClubBookPerRoom';
import ClubBookHistory from './ClubBookHistory';
import AmountPerPaymentMethod from './AmountPerPaymentMethod';
import ClubBookCreate from './Create';
import ClubBookDetail from './Detail';

import { fetchPaymentMethod, emptyFetchedPaymentMethod } from '../../../actions/paymentMethod';
import { fetchRoom, emptyFetchedRoom } from '../../../actions/room';
import { fetchMarketing, emptyFetchedMarketing } from '../../../actions/marketing';

export default function ClubBook() {
  const bookFormRef = useRef();
  const bookDetailRef = useRef();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterOutlet } = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const [hasCreatePermission] = useState(authenticatedUser.user.role_id !== 5);

  useEffect(() => {
    dispatch(fetchPaymentMethod());
    dispatch(fetchMarketing());
    return () => {
      // dispatch(emptyFetchedPaymentMethod());
      dispatch(emptyFetchedMarketing());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchRoom(filterOutlet.id));
    return () => dispatch(emptyFetchedRoom());
  }, [filterOutlet]);

  const onPressCreate = () => bookFormRef.current.showForm();

  return (
    <>
      <div className="md:flex">
        <div className="mb-3 w-full md:w-3/12 md:pr-2">
          <FilterOutlet />
        </div>
        <div className="mb-3 w-full md:w-3/12">
          <FilterDate />
        </div>
        <div className="block md:w-6/12 text-right">
          {hasCreatePermission && (
            <SubmitButton
              key="create"
              textClass="text-white text-xs mr-1"
              text="Tambah Booking"
              onClick={onPressCreate}
            />
          )}
        </div>
      </div>

      <div className="mt-3">
        <ClubBookPerRoom formRef={bookFormRef} detailRef={bookDetailRef} />
        <ClubBookHistory formRef={bookFormRef} detailRef={bookDetailRef} />
        <AmountPerPaymentMethod />
        {hasCreatePermission && <ClubBookCreate ref={bookFormRef} />}
        <ClubBookDetail ref={bookDetailRef} />
      </div>
    </>
  );
}
