import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { InlineTextInput, BasicButton, SubmitButton } from '../../../components/Base';
import { changePassword } from '../../../actions/password';

export default function Password() {
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  const onPressCancel = () => history.goBack();

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    dispatch(changePassword(data)).then(
      () => history.push('backoffice/dashboard'),
      () => setIsSubmittingForm(false),
    );
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Ubah Password</h3>
          </div>
        </div>

        <hr />

        {/* Form */}
        <div className="block w-full px-6 py-6 overflow-x-auto">
          <div className="xl:pr-60">
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <InlineTextInput
                type="password"
                title="Password Sekarang"
                inputRef={register({ required: true })}
                name="old_password"
                errorMessage={errors.old_password && 'Kolom Password Sekarang harus diisi'}
              />

              <InlineTextInput
                type="password"
                title="Password Baru"
                inputRef={register({ required: true })}
                name="new_password"
                errorMessage={errors.new_password && 'Kolom Password Baru harus diisi'}
              />

              <InlineTextInput
                type="password"
                title="Konfirmasi Password Baru"
                inputRef={register({ required: true })}
                name="c_new_password"
                errorMessage={errors.c_new_password && 'Kolom Konfirmasi Password Baru harus diisi'}
              />

              <div className="flex mt-10">
                <BasicButton type="button" text="Cancel" onClick={onPressCancel} />

                <div className="ml-2">
                  <SubmitButton type="submit" text="Submit" isLoading={isSubmittingForm} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
