import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';

import TherapistGradeCreate from './Create';
import { DeleteButton, SubmitButton } from '../../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../constants/env';
import Utils from '../../../helpers/utils';
import { deleteTherapistGrade } from '../../../actions/therapistGrade';

export default function TherapistGrade() {
  const therapistGradeFormRef = useRef();
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { refresh } = useSelector((state) => state.therapistGrade);

  const dispatch = useDispatch();

  useEffect(() => {
    initializeDataTables();
  }, [refresh]);

  const initializeDataTables = () => {
    $('#gradeDataTable').DataTable().destroy();
    $('#gradeDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/therapist-grade?source=datatables`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'name' },
        {
          data: 'price',
          render: (data) => Utils.numberFormat(data, 2),
        },
        {
          data: 'id',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="blue"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="Edit"
                  onClick={() => onPressEdit(rowData)}
                />
                <DeleteButton
                  color="red"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2"
                  onClick={() => onPressDelete(rowData)}
                />
              </div>,
              cell,
            ),
        },
      ],
    });
  };

  const onPressCreate = () => therapistGradeFormRef.current.showForm();
  const onPressEdit = (data) => therapistGradeFormRef.current.showFormWithInitialData(data);
  const onPressDelete = (data) => dispatch(deleteTherapistGrade(data.id));

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Ladies Grade</h3>
          </div>
          <div className="px-4">
            <SubmitButton
              textClass="text-white text-xs"
              text="Tambah Ladies Grade"
              onClick={onPressCreate}
            />
            <TherapistGradeCreate ref={therapistGradeFormRef} />
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table id="gradeDataTable" className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Nama Grade
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Harga
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-40"></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
