import React from 'react';
import { useSelector } from 'react-redux';

import ProfileDropdown from './ProfileDropdown';

export default function BackofficeNavbar() {
  const { result } = useSelector((state) => state.authenticatedUser);
  if (!result) return null;
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent p-4">
        <div className="w-full mx-auto flex justify-end items-center md:flex-no-wrap flex-wrap md:px-10 px-4">
          {/* User */}
          <span className="text-gray-500 text-sm uppercase hidden md:inline-block font-semibold pr-4">
            {result.user.name}
          </span>
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <ProfileDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
