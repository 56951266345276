import React, { forwardRef, useImperativeHandle, useMemo, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import {
  Modal,
  InlineSelect,
  Select,
  TextInput,
  InlineTextInput,
  BasicButton,
  SubmitButton,
} from '../../../../components/Base';

import {
  fetchMember,
  emptyFetchedMember,
  requestMemberTopupOtp,
  submitMemberTopup,
} from '../../../../actions/member';
import { fetchOutlet } from '../../../../actions/outlet';

function TopupForm(props, ref) {
  const { register, errors, control, getValues, handleSubmit } = useForm();
  const transactionTemplate = useMemo(
    () => ({
      member_id: '',
      outlet_id: '',
      amount: 0,
    }),
    [],
  );

  const { result: members } = useSelector((state) => state.member);
  const { result: outlets } = useSelector((state) => state.outlet);
  const dispatch = useDispatch();

  const memberOptions = members.map((m) => ({ value: m.id, label: `[${m.card_no}] ${m.name}` }));
  const outletOptions = outlets.map((o) => ({ value: o.id, label: o.name }));

  const [isVisible, setIsVisible] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [transactions, setTransactions] = useState([{ ...transactionTemplate }]);

  const [fmData, setFmData] = useState({});
  const [fmOtp, setFmOtp] = useState('');
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [resendOtpTimeDelay, setResendOtpTimeDelay] = useState(0);

  useEffect(() => {
    dispatch(fetchMember());
    dispatch(fetchOutlet());
    return () => dispatch(emptyFetchedMember());
  }, [dispatch]);

  useEffect(() => {
    if (resendOtpTimeDelay > 0) {
      setTimeout(() => setResendOtpTimeDelay(resendOtpTimeDelay - 1), 1000);
    }
  }, [resendOtpTimeDelay]);

  //

  useImperativeHandle(ref, () => ({
    showForm,
  }));

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setIsSubmittingForm(false);
  };

  //

  const onPressAddTransaction = () => {
    const temp = [...transactions];
    temp.push({ ...transactionTemplate });
    setTransactions([...temp]);
  };

  const onPressDeleteTransaction = (index) => {
    const temp = [...transactions];
    temp.splice(index, 1);

    setTransactions([...temp]);
  };

  //

  const onRequestOtp = (data) => {
    if (resendOtpTimeDelay > 0 || isSubmittingForm) return;

    setIsSubmittingForm(true);

    dispatch(requestMemberTopupOtp(data))
      .then(() => {
        setResendOtpTimeDelay(120);

        setFmData(data);
        setIsEditable(false);
        setIsSubmittingForm(false);
      })
      .catch(() => setIsSubmittingForm(false));
  };

  const onPressSubmitOtp = () => {
    if (fmOtp.trim() === '') {
      Swal.fire('Submit Error', 'Silahkan validasi OTP terlebih dahulu', 'error');
      return;
    }

    if (isSubmittingForm) return;
    setIsSubmittingForm(true);

    const data = { ...fmData, otp: fmOtp };

    dispatch(submitMemberTopup(data))
      .then(() => {
        setIsEditable(true);
        setIsSubmittingForm(false);
        setTransactions([{ ...transactionTemplate }]);
        setFmData({});
        setFmOtp('');
        closeForm();
      })
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <div>
      <Modal
        isVisible={isVisible}
        size="xlarge"
        ModalContent={
          <div className="sm:flex sm:items-start relative">
            <div className="mt-3 text-center sm:mt-0 w-full sm:text-left">
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                Top-Up / Refund Form
              </Dialog.Title>
              <div className="mt-5">
                <form id="createForm" onSubmit={handleSubmit(onRequestOtp)}>
                  <InlineSelect
                    disabled={!isEditable}
                    inputWidth="w-1/2"
                    title="Tipe Transaksi"
                    inputRef={register({ required: true })}
                    name="transaction_type"
                    options={['TOPUP', 'REFUND'].map((t) => (
                      <option key={t} value={t}>
                        {t}
                      </option>
                    ))}
                    defaultValue="TOPUP"
                    errorMessage={errors.transaction_type && 'Tipe Transaksi harus dipilih'}
                  />

                  <div className="w-full mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4">
                      <label className="mt-3 block uppercase text-gray-700 text-xs font-bold">
                        Transaksi Member
                      </label>
                      <div className="col-span-3 xl:col-span-3">
                        {transactions.map((purchaseRequestItem, index) => (
                          <div key={index}>
                            <div className="grid grid-cols-1 md:grid-cols-8 gap-1 md:gap-4">
                              <div className="md:col-span-3">
                                <div className="relative flex flex-wrap items-stretch w-full">
                                  <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name={`transactions[${index}].member_id`}
                                    render={({ onChange, value }) => (
                                      <Select
                                        isDisabled={!isEditable}
                                        className="w-full"
                                        placeholder="Pilih Member"
                                        onChange={(opt) => onChange(opt.value)}
                                        value={memberOptions.find((opt) => opt.value === value)}
                                        options={memberOptions}
                                      />
                                    )}
                                  />
                                </div>
                                {errors.transactions &&
                                  errors.transactions[index] &&
                                  errors.transactions[index].member_id && (
                                    <div className="text-xs text-red-500">Member harus dipilih</div>
                                  )}
                              </div>
                              <div className="md:col-span-2">
                                <div className="relative flex flex-wrap items-stretch w-full">
                                  <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name={`transactions[${index}].outlet_id`}
                                    render={({ onChange, value }) => (
                                      <Select
                                        isDisabled={!isEditable}
                                        className="w-full"
                                        placeholder="Pilih Outlet"
                                        onChange={(opt) => onChange(opt.value)}
                                        value={outletOptions.find((opt) => opt.value === value)}
                                        options={outletOptions}
                                      />
                                    )}
                                  />
                                </div>
                                {errors.transactions &&
                                  errors.transactions[index] &&
                                  errors.transactions[index].outlet_id && (
                                    <div className="text-xs text-red-500">Outlet harus dipilih</div>
                                  )}
                              </div>
                              <div className="md:col-span-2">
                                <TextInput
                                  disabled={!isEditable}
                                  type="number"
                                  inputRef={register({ required: true })}
                                  placeholder="Jumlah"
                                  name={`transactions[${index}].amount`}
                                  errorMessage={
                                    errors.transactions &&
                                    errors.transactions[index] &&
                                    errors.transactions[index].amount &&
                                    'Jumlah harus diisi'
                                  }
                                />
                              </div>
                              <div className="md:col-span-1">
                                {index > 0 && isEditable && (
                                  <SubmitButton
                                    type="button"
                                    color="red"
                                    textClass="text-white text-sm"
                                    additionalClass="h-11"
                                    text=" X "
                                    onClick={() => onPressDeleteTransaction(index)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}

                        {isEditable && (
                          <SubmitButton
                            compact
                            type="button"
                            color="green"
                            textClass="text-white text-xs"
                            paddingClass="px-4"
                            additionalClass="h-9"
                            text="Tambah Member"
                            onClick={() => onPressAddTransaction()}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <InlineTextInput
                    inputWidth="w-1/2"
                    type="number"
                    title="OTP"
                    name="otp"
                    value={fmOtp}
                    onChange={({ target: { value } }) => setFmOtp(value)}
                    button={`REQUEST OTP ${
                      resendOtpTimeDelay > 0 ? `(${resendOtpTimeDelay})` : ''
                    }`}
                    buttonType="submit"
                    buttonColor={resendOtpTimeDelay > 0 ? 'gray' : 'blue'}
                  />
                </form>
              </div>
            </div>
          </div>
        }
        ModalButton={
          <>
            <SubmitButton
              // type="submit"
              // form="createForm"
              text="Submit"
              textClass="text-white text-xs"
              isLoading={isSubmittingForm}
              onClick={onPressSubmitOtp}
            />

            {isEditable && (
              <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
            )}
          </>
        }
      />
    </div>
  );
}

export default forwardRef(TopupForm);
