import {
  FILTER_BY_OUTLET,
  FILTER_BY_DATE,
  FILTER_BY_RANGE_DATE,
  FILTER_BY_MONTH,
} from '../actions/_types';
import moment from 'moment';

const initialState = {
  filterOutlet: {},
  filterDate: {
    date: moment().format('YYYY-MM-DD'),
    startDate: moment().format('YYYY-MM-DD 00:00:00'),
    endDate: moment().format('YYYY-MM-DD 23:59:59'),
  },
  filterRangeDate: {
    startDate: moment().format('YYYY-MM-01 00:00:00'),
    endDate: moment().format('YYYY-MM-DD 23:59:59'),
  },
  filterMonth: {
    date: moment().format('YYYY-MM'),
    startDate: moment().format('YYYY-MM-01 00:00:00'),
    endDate: moment().add(1, 'months').format('YYYY-MM-01 00:00:00'),
  },
};

export default function outlet(state = initialState, action) {
  switch (action.type) {
    case FILTER_BY_OUTLET:
      return {
        ...state,
        filterOutlet: action.result,
      };

    case FILTER_BY_DATE:
      return {
        ...state,
        filterDate: action.result,
      };

    case FILTER_BY_RANGE_DATE:
      return {
        ...state,
        filterRangeDate: action.result,
      };

    case FILTER_BY_MONTH:
      return {
        ...state,
        filterMonth: action.result,
      };

    default:
      return state;
  }
}
